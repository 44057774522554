export default {
  am: 'am', // Amharic
  ar: 'sa', // Arabic
  az: 'az', // Azerbaijani
  bn: 'bd', // Bengali
  be: 'be', // Belarusian
  cs: 'cz', // Czech
  da: 'dk', // Danish
  de: 'de', // German
  en: 'gb', // English
  es: 'es', // Spanish
  et: 'ee', // Estonian
  fa: 'ir', // Persian
  fr: 'fr', // French
  bg: 'bg', // Bulgarian
  hi: 'in', // Hindi
  hu: 'hu', // Hungarian
  it: 'it', // Italian
  ja: 'jp', // Japanese
  ko: 'kr', // Korean
  lv: 'lv', // Latvian
  ms: 'my', // Malay
  nl: 'nl', // Dutch
  pl: 'pl', // Polish
  pt: 'pt', // Portuguese
  ro: 'ro', // Romanian
  ru: 'ru', // Russian
  sk: 'sk', // Slovak
  th: 'th', // Thai
  tr: 'tr', // Turkish
  uk: 'ua', // Ukrainian
  uz: 'uz', // Uzbek
  vi: 'vn', // Vietnamese
  zh: 'cn', // Chinese
  sv: 'se', // Swedish
  sl: 'si', // Slovenian
  rs: 'rs', // Serbian
  nn: 'no', // Norwegian Nynorsk
  nb: 'no', // Norwegian Bokmal
  el: 'gr', // Modern Greek (1453-)
  fi: 'fi', // Finnish
  ca: 'es-ct', // Catalan
  hr: 'hr', // Croatian
  la: 'va', // Latin
  lt: 'lt', // Lithuanian
  mk: 'mk', // Macedonian
  mt: 'mt', // Maltese
  ga: 'ie', // Irish
  sq: 'al', // Albanian
  sr: 'rs', // Serbian
};
