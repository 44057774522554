<template>
  <div id="app" class="app-container">
    <SideMenu />
    <div class="page-content">
      <NuxtLoadingIndicator />
      <NuxtPage />
    </div>
    <UNotifications />
  </div>
</template>
<script>

import SideMenu from '~/components/MainPage/SideMenu.vue'

export default {
  components: {
    SideMenu,
  },
  mounted() {
    if (this.$config.public.instanceName === 'pons') {
      this.runJiraScript();
    }
  },
  methods: {
    runJiraScript() {
      const jhdScript = document.createElement('script');
      jhdScript.type = 'text/javascript';
      jhdScript.setAttribute('data-jsd-embedded', '');
      jhdScript.setAttribute('data-key', 'b08820bc-bdb2-4e67-9b55-55a86516e589');
      jhdScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
      jhdScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
      if (jhdScript.readyState) {
        jhdScript.onreadystatechange = () => {
          if (
            jhdScript.readyState === 'loaded' ||
            jhdScript.readyState === 'complete'
          ) {
            jhdScript.onreadystatechange = null;
            this.onScriptLoaded();
          }
        };
      } else {
        jhdScript.onload = this.onScriptLoaded;
      }
      document.getElementsByTagName('head')[0].appendChild(jhdScript);
    },
    onScriptLoaded() {
      let event;
      if (typeof Event === 'function') {
        event = new Event('DOMContentLoaded', { bubbles: true, cancelable: true });
      } else {
        event = document.createEvent('Event');
        event.initEvent('DOMContentLoaded', true, true);
      }
      document.dispatchEvent(event);
    }
  },

}

</script>

<style>
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-content {
  margin-left: 5rem;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
</style>
