import { iso6393 } from 'iso-639-3'
import relation from './relation'
import 'flag-icon-css/css/flag-icons.css'
import { messages } from '../../../locales/locale.js'

const savedLanguage = localStorage.getItem('selectedLanguage')
const detectValue = messages[savedLanguage]?.text_translator?.detect || 'Detect'

export function toTitleCase(word) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}

function createLanguageNameToCodeMap(codeType = 'iso6391', mappedLanguage = 'en') {
  const displayNames = new Intl.DisplayNames([mappedLanguage], { type: 'language' })
  const map = new Map()

  for (const lang of iso6393) {
    if (lang['iso6391']) {
      if (lang[codeType]) {
        const localizedName = displayNames.of(lang[codeType])
        if (localizedName) {
          map.set(localizedName.toLowerCase(), lang[codeType])
        }
      }
    }
  }

  return map
}

function getCodeFromLanguageName(languageName, codeType = 'iso6391') {
  const lookupMap = createLanguageNameToCodeMap(codeType, savedLanguage ?? 'en')
  return lookupMap.get(languageName.toLowerCase()) || null
}

export function getLanguageName(code) {
  if (code === null) {
    return ''
  }
  const displayNames = new Intl.DisplayNames([savedLanguage], { type: 'language' })
  return toTitleCase(displayNames.of(code))
}

export function getIso6391Code(languageName) {
  return getCodeFromLanguageName(languageName, 'iso6391')
}

export function getFlagIconClass(languageName) {
  try {
    if (languageName !== null && languageName !== detectValue) {
      const flagCode = relation[getCodeFromLanguageName(languageName)]
      if (!flagCode) {
        return ''
      }
      return flagCode
    }
  } catch {
    return ''
  }
}

export function getIso6393Code(languageName) {
  if (languageName) return getCodeFromLanguageName(languageName, 'iso6393')
}

export function getLanguageOwnName(code) {
  if (!code) {
    return ''
  }
  const displayNames = new Intl.DisplayNames([code], { type: 'language' })
  return toTitleCase(displayNames.of(code))
}
