export const messages = {
  en: {
    notifications: {
      glossary_add_entry: 'Please fill in all required fields.',
    },
    menu: {
      text_translator: 'Text Translator',
      new_document: 'Document Translator',
      documents: 'Documents',
      last_document: 'Last Document',
      document_editor: 'Document Editor',
      language_correction: 'Text Polisher',
      glossary: 'Glossaries',
      user_panel: 'User Panel',
      admin_panel: 'Admin Panel',
      logout: 'Logout',
      neutral: 'Neutral',
    },
    text_translator: {
      source_select: 'Select source language',
      target_select: 'Select target language',
      detected_language: 'Detected language',
      select_tone: 'Select Tone',
      select_domain: 'Select Domain',
      show_changes: 'Show changes:',
      select_language: 'Select language',
      search: 'Search',
      no_options: 'No options',
      no_results: 'No results for: ',
      detect: 'Detect Language',
      placeholder: 'Enter the text you want to translate...',
      placeholder_correction: 'Enter the text you want to correct...',
    },
    documents: {
      supported_documents: 'Supported formats: ',
      drag_or_browse: 'Drag & Drop or Choose file to upload',
      upload: 'Choose File',
      upload_new: 'Add new file',
      document_uploaded: 'Document uploaded!',
      ready_translate: 'We are ready to translate your document',
      translate: 'Translate',
      remove: 'Remove',
      document_translated: 'Your document has been translated!',
      download: 'Download',
      document_name: 'Document name:',
      source_language: 'Source language:',
      target_language: 'Target language:',
      actions: 'Actions:',
      max: 'You can upload a maximum of 5 documents at a time.',
      tone: 'Tone',
      domain: 'Domain',
      glossary: 'Glossary',
      uploading: 'Uploading the file...',
      sending_server: 'File uploaded, sending to server...',
      uploading_error: 'Something went wrong. Please try again.',
      uploading_error_large: 'File is too large. Max size is 100MB.',
      translating_warning: 'Insufficient information to translate.',
      document_ready: 'Document is ready for translation.',
      document_translated: 'Document has been translated successfully!',
      document_translating: 'Translating document... {progress}%',
      detecting: 'Detecting...',
      empty_documents: 'You have not added any documents yet.',
    },
    documents_viewer: {
      file_name: 'File name',
      from: 'From',
      to: 'To',
      status: 'Status',
      created: 'Created',
      actions: 'Actions',
      add_document: 'Add document',
      delete_documents: 'Delete documents',
      delete_document: 'Delete document',
      sure: 'Are you sure you want to delete ',
      sure_documents: 'Are you sure you want to delete selected documents?',
      delete: 'Delete',
      search: 'Search by document name',
      rows: 'Rows per page',
      no: 'No',
      yes: 'Yes',
      edit: 'Edit',
      download: 'Download',
      info: 'Information about document:',
      file_type: 'File type:',
      chars_before: 'Characters before translation:',
      chars_after: 'Characters after translation:',
      translated: 'Translated',
      converted: 'Converted',
      translating: 'Translating',
      pending: 'Pending',
      failed: 'Failed',
    },
    documents_editor: {
      editor_workspace: 'Editor Workspace: ',
      doc_name: 'Document Name:   ',
      download_doc: 'Download document',
      find_replace: 'Find & Replace',
      last_edit: 'Last Edit',
      edited: 'Edited',
      source_text: 'Source text',
      target_text: 'Target text',
      find_next: 'Find next',
      replace: 'Replace',
      replace_all: 'All',
      search: 'Search in document',
      changes: 'Changes in row',
      automatically: 'saved automatically',
      download_translation: 'Download translation',
      download_source: 'Download original',
      find: 'Find...',
      replace_with: 'Replace to...',
      pdf: '.PDF',
      docx: '.DOCX',
      drag: 'Drag to move the window',
    },
    modals: {
      char_limit: 'Character limit:',
      char_info: 'Please edit the text to fit within the limit.',
      char_button: 'Edit input',
      language_error: 'Oops! You forgot to choose a language.',
      language_info: 'Select your target language to start.',
      language_button: 'Select language',
      document_error: 'Oops! There was a problem with the translation.',
      document_info: 'Upload another file and try again.',
      document_button: 'Try again',
    },
    footer: {
      privacy_policy: 'Privacy Policy',
      imprint: 'Imprint',
      terms_and_conditions: 'Terms and conditions of use',
      cookie_policy: 'Cookie Policy',
      contact: 'Contact',
      legal_notice: 'Legal notice',
      data_protection: 'Data protection',
      contact: 'Contact',
    },
    glossary: {
      create_glossary: 'Create Glossary',
      glossary_name: 'Glossary Name',
      glossary_list: 'Glossary List',
      add_term: 'Add Term',
      delete: 'Delete',
      import_csv: 'Import CSV',
      export_csv: 'Export CSV',
      rename: 'Rename',
      added_terms: 'Added terms',
      search_glossary: 'Search for terms...',
      source_text: 'Source language',
      target_text: 'Target language',
      enter_source: 'Enter source text',
      enter_target: 'Enter target text',
      glossary: 'Glossary',
      loading: 'Glossaries are being loaded...',
      entry_already_exists: 'Entry already exists',
      empty_glossary: 'No entries found in the glossary.',
      glossary_already_exists: 'Glossary already exists',
    },
    copyright: {
      copyright: '© Laniqo. All rights reserved.',
    },
    admin: {
      delete_user: 'Delete user',
      create_user: 'Create New User',
      new_user: 'New User',
      submit: 'Submit',
      activate_user: 'Activate user',
      activate_help: 'Check whether the user should be active immediately upon creation.',
      verify_user: 'Verify email',
      verify_help: 'Check the box if the user needs to verify their email.',
      search_email: 'Search by email',
      required: 'Required',
      characters: 'Must be at least 8 characters.',
      invalid_email: 'Invalid email address.',
      first_name: 'First name',
      last_name: 'Last name',
      email: 'Email',
      creation_date: 'Creation date',
      active: 'Active',
      password: 'Password',
    },
    user_panel: {
      change_password: 'Change password',
      change_password_description:
        'You are being redirected to the next page to change your password.',
      change_password_button: 'Change password',
      access_token: 'Access Token',
      access_token_description:
        "Access tokens authenticate the user's identity in the Laniqo MT API and allow applications to perform actions based on the token's permissions.",
      api_key: 'API Key',
      api_key_description: 'Click button to generate Key',
      generate_button: 'Generate',
      profile: 'Profile',
      profile_description:
        'The profile section contains information about you. You can edit and update it.',
      email: 'Email',
      email_description: 'Enter your email address',
      first_name: 'First Name',
      first_name_description: 'Enter the first part of your name',
      last_name: 'Last Name',
      last_name_description: 'Enter the last part of your name',
      update_button: 'Update',
      contact_admin: 'Contact Admin',
      contact_admin_description:
        'For more complex issues or assistance beyond standard support, please contact your administrator.',
      copy_icon_alt: 'Copy Icon',
      edit_icon_alt: 'Edit Icon',
      input_new_email_placeholder: 'Input new email',
      enter_first_name_placeholder: 'Enter first name',
      enter_last_name_placeholder: 'Enter last name',
      update_info: 'User Info has been changed!',
      open: 'Open Admin Panel',
    },
    pagination: {
      prev: 'Previous page',
      next: 'Next page',
      first: 'First page',
      last: 'Last page',
    },
  }, 
  pl: {
    notifications: {
      glossary_add_entry: 'Proszę wypełnić wszystkie wymagane pola.',
    },
    menu: {
      text_translator: 'Tłumacz tekstu',
      new_document: 'Tłumacz dokumentów',
      documents: 'Dokumenty',
      last_document: 'Ostatni dokument',
      document_editor: 'Edytor dokumentów',
      language_correction: 'Korekta językowa',
      glossary: 'Glosariusze',
      user_panel: 'Panel użytkownika',
      admin_panel: 'Panel administratora',
      logout: 'Wyloguj się',
      neutral: 'Neutralny',
    },
    text_translator: {
      source_select: 'Wybierz język źródłowy',
      target_select: 'Wybierz język docelowy',
      detected_language: 'Wykryty język',
      select_tone: 'Wybierz ton',
      select_domain: 'Wybierz domenę',
      show_changes: 'Pokaż zmiany:',
      select_language: 'Wybierz język',
      search: 'Szukaj',
      no_options: 'Brak opcji',
      no_results: 'Brak wyników dla: ',
      detect: 'Wykryj Język',
      placeholder: 'Wprowadź tekst, który chcesz przetłumaczyć...',
      placeholder_correction: 'Wprowadź tekst, który chcesz poprawić...',
    },
    documents: {
      supported_documents: 'Obsługiwane formaty: ',
      drag_or_browse: 'Przeciągnij i upuść lub wybierz plik do przesłania',
      upload: 'Wybierz plik',
      upload_new: 'Dodaj nowy plik',
      document_uploaded: 'Dokument przesłany!',
      ready_translate: 'Jesteśmy gotowi do tłumaczenia Twojego dokumentu',
      translate: 'Tłumacz',
      remove: 'Usuń',
      document_translated: 'Twój dokument został przetłumaczony!',
      download: 'Pobierz',
      document_name: 'Nazwa dokumentu:',
      source_language: 'Język źródłowy:',
      target_language: 'Język docelowy:',
      actions: 'Akcje:',
      max: 'Możesz przesłać maksymalnie 5 dokumentów jednocześnie.',
      tone: 'Ton',
      domain: 'Domena',
      glossary: 'Glosariusz',
      uploading: 'Przesyłanie pliku...',
      sending_server: 'Plik przesłany, wysyłanie na serwer...',
      uploading_error: 'Ups! Coś poszło nie tak. Spróbuj ponownie.',
      uploading_error_large: 'Plik jest za duży. Maksymalny rozmiar to 100MB.',
      document_ready: 'Dokument jest gotowy do tłumaczenia.',
      document_translated: 'Dokument został pomyślnie przetłumaczony!',
      document_translating: 'Tłumaczenie dokumentu... {progress}%',
      detecting: 'Wykrywanie...',
      translating_warning: 'Niewystarczające informacje do przetłumaczenia.',
      empty_documents: 'Nie dodałeś jeszcze żadnych dokumentów.',
    },
    documents_viewer: {
      file_name: 'Nazwa pliku',
      from: 'Od',
      to: 'Do',
      status: 'Status',
      created: 'Utworzono',
      actions: 'Akcje',
      add_document: 'Dodaj dokument',
      delete_documents: 'Usuń dokumenty',
      delete_document: 'Usuń dokument',
      sure: 'Czy na pewno chcesz usunąć ',
      sure_documents: 'Czy na pewno chcesz usunąć wybrane dokumenty?',
      delete: 'Usuń',
      search: 'Szukaj według nazwy dokumentu',
      rows: 'Wiersze na stronę',
      no: 'Nie',
      yes: 'Tak',
      edit: 'Edytuj',
      download: 'Pobierz',
      info: 'Informacje o dokumencie:',
      file_type: 'Typ pliku:',
      chars_before: 'Znaki przed tłumaczeniem:',
      chars_after: 'Znaki po tłumaczeniu:',
      translated: 'Przetłumaczono',
      converted: 'Skonwertowano',
      translating: 'Tłumaczenie',
      failed: 'Niepowodzenie',
      pending: 'Oczekiwanie',
    },
    documents_editor: {
      editor_workspace: 'Przestrzeń robocza edytora: ',
      doc_name: 'Nazwa dokumentu:   ',
      download_doc: 'Pobierz dokument',
      find_replace: 'Znajdź i zamień',
      last_edit: 'Ostatnia edycja',
      edited: 'Edytowano',
      source_text: 'Tekst źródłowy',
      target_text: 'Tekst docelowy',
      find_next: 'Znajdź następny',
      replace: 'Zamień',
      replace_all: 'Wszystko',
      search: 'Szukaj w dokumencie',
      changes: 'Zmiany w wierszu',
      automatically: 'zapisane automatycznie',
      download_translation: 'Pobierz tłumaczenie',
      download_source: 'Pobierz oryginał',
      find: 'Znajdź...',
      replace_with: 'Zamień na...',
      pdf: '.PDF',
      docx: '.DOCX',
      drag: 'Przeciągnij, aby przesunąć okno',
    },
    modals: {
      char_limit: 'Limit znaków:',
      char_info: 'Proszę edytować tekst, aby zmieścić się w limicie.',
      char_button: 'Edytuj dane wejściowe',
      language_error: 'Ups! Zapomniałeś wybrać język.',
      language_info: 'Wybierz język docelowy, aby rozpocząć.',
      language_button: 'Wybierz język',
      document_error: 'Ups! Wystąpił problem z tłumaczeniem.',
      document_info: 'Prześlij inny plik i spróbuj ponownie.',
      document_button: 'Spróbuj ponownie',
    },
    footer: {
      privacy_policy: 'Polityka prywatności',
      imprint: 'Impressum',
      terms_and_conditions: 'Warunki użytkowania',
      cookie_policy: 'Polityka plików cookie',
      contact: 'Kontakt',
      legal_notice: 'Informacje prawne',
      data_protection: 'Ochrona danych',
      contact: 'Kontakt',
    },
    glossary: {
      create_glossary: 'Utwórz glosariusz',
      glossary_name: 'Nazwa glosariusza',
      glossary_list: 'Lista glosariuszy',
      add_term: 'Dodaj termin',
      delete: 'Usuń',
      import_csv: 'Importuj CSV',
      export_csv: 'Eksportuj CSV',
      rename: 'Zmień nazwę',
      added_terms: 'Dodane terminy',
      search_glossary: 'Szukaj terminów...',
      source_text: 'Język źródłowy',
      target_text: 'Język docelowy',
      enter_source: 'Wprowadź tekst źródłowy',
      enter_target: 'Wprowadź tekst docelowy',
      glossary: 'Glosariusz',
      loading: 'Glosariusze s ładowane...',
      entry_already_exists: 'Wpis już istnieje',
      empty_glossary: 'Nie znaleziono wpisów w glosariuszu.',
      glossary_already_exists: 'Glosariusz już istnieje',
    },
    copyright: {
      copyright: '© Laniqo. Wszelkie prawa zastrzeżone.',
    },
    admin: {
      delete_user: 'Usuń użytkownika',
      create_user: 'Utwórz nowego użytkownika',
      new_user: 'Nowy użytkownik',
      submit: 'Zatwierdź',
      activate_user: 'Aktywuj użytkownika',
      activate_help: 'Zaznacz, czy użytkownik powinien być aktywny natychmiast po utworzeniu.',
      verify_user: 'Zweryfikuj email',
      verify_help: 'Zaznacz pole, jeśli użytkownik musi zweryfikować swój email.',
      search_email: 'Szukaj według emaila',
      required: 'Wymagane',
      characters: 'Musi mieć co najmniej 8 znaków.',
      invalid_email: 'Nieprawidłowy adres email.',
      first_name: 'Imię',
      last_name: 'Nazwisko',
      email: 'Email',
      creation_date: 'Data utworzenia',
      active: 'Aktywny',
      password: 'Hasło',
    },
    user_panel: {
      change_password: 'Zmień hasło',
      change_password_description:
        'Zostaniesz przekierowany na następną stronę, aby zmienić hasło.',
      change_password_button: 'Zmień hasło',
      access_token: 'Token dostępu',
      access_token_description:
        'Tokeny dostępu uwierzytelniają tożsamość użytkownika w Laniqo MT API i pozwalają aplikacjom wykonywać działania na podstawie uprawnień tokena.',
      api_key: 'Klucz API',
      api_key_description: 'Kliknij przycisk, aby wygenerować klucz',
      generate_button: 'Generuj',
      profile: 'Profil',
      profile_description:
        'Sekcja profilu zawiera informacje o Tobie. Możesz je edytować i aktualizować.',
      email: 'Email',
      email_description: 'Wprowadź swój adres email',
      first_name: 'Imię',
      first_name_description: 'Wprowadź pierwszą część swojego imienia',
      last_name: 'Nazwisko',
      last_name_description: 'Wprowadź ostatnią część swojego nazwiska',
      update_button: 'Aktualizuj',
      contact_admin: 'Skontaktuj się z administratorem',
      contact_admin_description:
        'W przypadku bardziej złożonych problemów lub pomocy wykraczającej poza standardowe wsparcie, skontaktuj się z administratorem',
      copy_icon_alt: 'Ikona kopiowania',
      edit_icon_alt: 'Ikona edycji',
      input_new_email_placeholder: 'Wprowadź nowy email',
      enter_first_name_placeholder: 'Wprowadź imię',
      enter_last_name_placeholder: 'Wprowadź nazwisko',
      update_info: 'Informacje o użytkowniku zostały zmienione!',
      open: 'Otwórz panel administratora',
    },
    pagination: {
      prev: 'Poprzednia strona',
      next: 'Następna strona',
      first: 'Pierwsza strona',
      last: 'Ostatnia strona',
    },
  },
  de: {
    notifications: {
      glossary_add_entry: 'Bitte füllen Sie alle erforderlichen Felder aus.',
    },
    menu: {
      text_translator: 'Textübersetzer',
      new_document: 'Dokumentübersetzer',
      documents: 'Dokumente',
      last_document: 'Letztes Dokument',
      document_editor: 'Dokumenteditor',
      language_correction: 'Text Korrekturen',
      glossary: 'Glossare',
      user_panel: 'Benutzerpanel',
      admin_panel: 'Admin-Panel',
      logout: 'Abmelden',
      neutral: 'Neutral',
    },
    text_translator: {
      source_select: 'Quellsprache auswählen',
      target_select: 'Zielsprache auswählen',
      detected_language: 'Erkannte Sprache',
      select_tone: 'Ton auswählen',
      select_domain: 'Domäne auswählen',
      show_changes: 'Änderungen anzeigen:',
      select_language: 'Sprache auswählen',
      search: 'Suche',
      no_options: 'Keine Optionen',
      no_results: 'Keine Ergebnisse für: ',
      detect: 'Sprache Erkennen',
      placeholder: 'Geben Sie den Text ein, den Sie übersetzen möchten...',
      placeholder_correction: 'Geben Sie den Text ein, den Sie korrigieren möchten...',
    },
    documents: {
      supported_documents: 'Unterstützte Formate: ',
      drag_or_browse: 'Ziehen & Ablegen oder Datei zum Hochladen auswählen',
      upload: 'Datei auswählen',
      upload_new: 'Neue Datei hinzufügen',
      document_uploaded: 'Dokument hochgeladen!',
      ready_translate: 'Wir sind bereit, Ihr Dokument zu übersetzen',
      translate: 'Übersetzen',
      remove: 'Entfernen',
      document_translated: 'Ihr Dokument wurde übersetzt!',
      download: 'Herunterladen',
      document_name: 'Dokumentname:',
      source_language: 'Quellsprache:',
      target_language: 'Zielsprache:',
      actions: 'Aktionen:',
      max: 'Sie können maximal 5 Dokumente gleichzeitig hochladen.',
      tone: 'Ton',
      domain: 'Domäne',
      glossary: 'Glossar',
      uploading: 'Datei wird hochgeladen...',
      sending_server: 'Datei hochgeladen, wird an den Server gesendet...',
      uploading_error: 'Hoppla! Etwas ist schief gelaufen. Bitte versuche es erneut.',
      uploading_error_large: 'Datei ist zu groß. Maximale Größe ist 100MB.',
      document_ready: 'Dokument ist bereit zur Übersetzung.',
      document_translated: 'Dokument wurde erfolgreich übersetzt!',
      document_translating: 'Dokument wird übersetzt... {progress}%',
      detecting: 'Erkennen...',
      translating_warning: 'Unzureichende Informationen zum Übersetzen.',
      empty_documents: 'Sie haben noch keine Dokumente hinzugefügt.',
    },
    documents_viewer: {
      file_name: 'Dateiname',
      from: 'Von',
      to: 'Bis',
      status: 'Status',
      created: 'Erstellt',
      actions: 'Aktionen',
      add_document: 'Dokument hinzufügen',
      delete_documents: 'Dokumente löschen',
      delete_document: 'Dokument löschen',
      sure: 'Sind Sie sicher, dass Sie löschen möchten ',
      sure_documents: 'Sind Sie sicher, dass Sie die ausgewählten Dokumente löschen möchten?',
      delete: 'Löschen',
      search: 'Nach Dokumentnamen suchen',
      rows: 'Zeilen pro Seite',
      no: 'Nein',
      yes: 'Ja',
      edit: 'Bearbeiten',
      download: 'Herunterladen',
      info: 'Informationen über das Dokument:',
      file_type: 'Dateityp:',
      chars_before: 'Zeichen vor der Übersetzung:',
      chars_after: 'Zeichen nach der Übersetzung:',
      translated: 'Übersetzt',
      converted: 'Konvertiert',
      translating: 'Übersetzung',
      pending: 'Ausstehend',
      failed: 'Fehlgeschlagen',
    },
    documents_editor: {
      editor_workspace: 'Editor-Arbeitsbereich: ',
      doc_name: 'Dokumentname:   ',
      download_doc: 'Dokument herunterladen',
      find_replace: 'Suchen & Ersetzen',
      last_edit: 'Letzte Bearbeitung',
      edited: 'Bearbeitet',
      source_text: 'Quelltext',
      target_text: 'Zieltext',
      find_next: 'Nächstes finden',
      replace: 'Ersetzen',
      replace_all: 'Ersetzen',
      search: 'Im Dokument suchen',
      changes: 'Änderungen in der Zeile',
      automatically: 'automatisch gespeichert',
      download_translation: 'Übersetzung herunterladen',
      download_source: 'Original herunterladen',
      find: 'Suchen...',
      replace_with: 'Ersetzen durch...',
      pdf: '.PDF',
      docx: '.DOCX',
      drag: 'Ziehen, um das Fenster zu bewegen',
    },
    modals: {
      char_limit: 'Zeichenlimit:',
      char_info: 'Bitte bearbeiten Sie den Text, um innerhalb des Limits zu bleiben.',
      char_button: 'Eingabe bearbeiten',
      language_error: 'Ups! Sie haben vergessen, eine Sprache auszuwählen.',
      language_info: 'Wählen Sie Ihre Zielsprache, um zu beginnen.',
      language_button: 'Sprache auswählen',
      document_error: 'Ups! Es gab ein Problem mit der Übersetzung.',
      document_info: 'Laden Sie eine andere Datei hoch und versuchen Sie es erneut.',
      document_button: 'Erneut versuchen',
    },
    footer: {
      privacy_policy: 'Datenschutzrichtlinie',
      imprint: 'Impressum',
      terms_and_conditions: 'Nutzungsbedingungen',
      cookie_policy: 'Cookie-Richtlinie',
      contact: 'Kontakt',
      legal_notice: 'Rechtlicher Hinweis',
      data_protection: 'Datenschutz',
      contact: 'Kontakt',
    },
    glossary: {
      create_glossary: 'Glossar erstellen',
      glossary_name: 'Glossarname',
      glossary_list: 'Glossarliste',
      add_term: 'Begriff hinzufügen',
      delete: 'Löschen',
      import_csv: 'CSV importieren',
      export_csv: 'CSV exportieren',
      rename: 'Umbenennen',
      added_terms: 'Hinzugefügte Begriffe',
      search_glossary: 'Begriff suchen...',
      source_text: 'Quellsprache',
      target_text: 'Zielsprache',
      enter_source: 'Quelltext eingeben',
      enter_target: 'Zieltext eingeben',
      glossary: 'Glossar',
      loading: 'Glossare werden geladen...',
      entry_already_exists: 'Eintrag existiert bereits',
      empty_glossary: 'Keine Einträge im Glossar gefunden.',
      glossary_already_exists: 'Glossar existiert bereits',
    },
    copyright: {
      copyright: '© Laniqo. Alle Rechte vorbehalten.',
    },
    admin: {
      delete_user: 'Benutzer löschen',
      create_user: 'Neuen Benutzer erstellen',
      new_user: 'Neuer Benutzer',
      submit: 'Einreichen',
      activate_user: 'Benutzer aktivieren',
      activate_help: 'Überprüfen Sie, ob der Benutzer sofort nach der Erstellung aktiv sein soll.',
      verify_user: 'E-Mail verifizieren',
      verify_help: 'Aktivieren Sie das Kontrollkästchen, wenn der Benutzer seine E-Mail verifizieren muss.',
      search_email: 'Nach E-Mail suchen',
      required: 'Erforderlich',
      characters: 'Muss mindestens 8 Zeichen lang sein.',
      invalid_email: 'Ungültige E-Mail-Adresse.',
      first_name: 'Vorname',
      last_name: 'Nachname',
      email: 'E-Mail',
      creation_date: 'Erstellungsdatum',
      active: 'Aktiv',
      password: 'Passwort',
    },
    user_panel: {
      change_password: 'Passwort ändern',
      change_password_description:
        'Sie werden auf die nächste Seite weitergeleitet, um Ihr Passwort zu ändern.',
      change_password_button: 'Passwort ändern',
      access_token: 'Zugriffstoken',
      access_token_description:
        'Zugriffstoken authentifizieren die Identität des Benutzers in der Laniqo MT API und ermöglichen Anwendungen, Aktionen basierend auf den Berechtigungen des Tokens durchzuführen.',
      api_key: 'API-Schlüssel',
      api_key_description: 'Klicken Sie auf die Schaltfläche, um den Schlüssel zu generieren',
      generate_button: 'Generieren',
      profile: 'Profil',
      profile_description:
        'Der Profilbereich enthält Informationen über Sie. Sie können es bearbeiten und aktualisieren.',
      email: 'E-Mail',
      email_description: 'Geben Sie Ihre E-Mail-Adresse ein',
      first_name: 'Vorname',
      first_name_description: 'Geben Sie den ersten Teil Ihres Namens ein',
      last_name: 'Nachname',
      last_name_description: 'Geben Sie den letzten Teil Ihres Namens ein',
      update_button: 'Aktualisieren',
      contact_admin: 'Administrator kontaktieren',
      contact_admin_description:
        'Für komplexere Probleme oder Unterstützung über den Standard-Support hinaus wenden Sie sich bitte an Ihren Administrator',
      copy_icon_alt: 'Kopiersymbol',
      edit_icon_alt: 'Bearbeitungssymbol',
      input_new_email_placeholder: 'Neue E-Mail eingeben',
      enter_first_name_placeholder: 'Vorname eingeben',
      enter_last_name_placeholder: 'Nachname eingeben',
      update_info: 'Benutzerinformationen wurden geändert!',
      open: 'Admin-Panel öffnen',
    },
    pagination: {
      prev: 'Vorherige Seite',
      next: 'Nächste Seite',
      first: 'Erste Seite',
      last: 'Letzte Seite',
    },
  },
  fr: {
    notifications: {
      glossary_add_entry: 'Veuillez remplir tous les champs obligatoires.',
    },
    menu: {
      text_translator: 'Traducteur de Texte',
      new_document: 'Traducteur de Documents',
      documents: 'Documents',
      last_document: 'Dernier Document',
      document_editor: 'Éditeur de Documents',
      language_correction: 'Correction de Texte',
      glossary: 'Glossaires',
      user_panel: 'Panneau Utilisateur',
      admin_panel: 'Panneau Admin',
      logout: 'Déconnexion',
      neutral: 'Neutral',
    },
    text_translator: {
      source_select: 'Sélectionner la langue source',
      target_select: 'Sélectionner la langue cible',
      detected_language: 'Langue détectée',
      select_tone: 'Sélectionner le Ton',
      select_domain: 'Sélectionner le Domaine',
      show_changes: 'Afficher les modifications:',
      select_language: 'Sélectionner la langue',
      search: 'Rechercher',
      no_options: 'Aucune option',
      no_results: 'Aucun résultat pour: ',
      detect: 'Détecter La Langue',
      placeholder: 'Entrez le texte que vous souhaitez traduire...',
      placeholder_correction: 'Entrez le texte que vous souhaitez corriger...',
    },
    documents: {
      supported_documents: 'Formats pris en charge : ',
      drag_or_browse: 'Glisser-déposer ou choisir un fichier à télécharger',
      upload: 'Choisir le fichier',
      upload_new: 'Ajouter un nouveau fichier',
      document_uploaded: 'Document téléchargé!',
      ready_translate: 'Nous sommes prêts à traduire votre document',
      translate: 'Traduire',
      remove: 'Supprimer',
      document_translated: 'Votre document a été traduit!',
      download: 'Télécharger',
      document_name: 'Nom du document:',
      source_language: 'Langue source:',
      target_language: 'Langue cible:',
      actions: 'Actions:',
      max: 'Vous pouvez télécharger un maximum de 5 documents à la fois.',
      tone: 'Ton',
      domain: 'Domaine',
      glossary: 'Glossaire',
      uploading: 'Téléchargement du fichier...',
      sending_server: 'Fichier téléchargé, envoi au serveur...',
      uploading_error: "Oups! Quelque chose s'est mal passé. Veuillez réessayer.",
      uploading_error_large: 'Le fichier est trop volumineux. La taille maximale est de 100MB.',
      document_ready: 'Le document est prêt pour la traduction.',
      document_translated: 'Le document a été traduit avec succès!',
      document_translating: 'Le document est en cours de traduction... {progress}%',
      detecting: 'Détection...',
      translating_warning: 'Informations insuffisantes pour traduire.',
      empty_documents: 'Vous n\'avez pas encore ajouté de documents.',
    },
    documents_viewer: {
      file_name: 'Nom du fichier',
      from: 'De',
      to: 'À',
      status: 'Statut',
      created: 'Créé',
      actions: 'Actions',
      add_document: 'Ajouter un document',
      delete_documents: 'Supprimer des documents',
      delete_document: 'Supprimer le document',
      sure: 'Êtes-vous sûr de vouloir supprimer ',
      sure_documents: 'Êtes-vous sûr de vouloir supprimer les documents sélectionnés?',
      delete: 'Supprimer',
      search: 'Rechercher par nom de document',
      rows: 'Lignes par page',
      no: 'Non',
      yes: 'Oui',
      edit: 'Modifier',
      download: 'Télécharger',
      info: 'Informations sur le document:',
      file_type: 'Type de fichier:',
      chars_before: 'Caractères avant traduction:',
      chars_after: 'Caractères après traduction:',
      translated: 'Traduit',
      converted: 'Converti',
      translating: 'Traduction en cours',
      pending: 'En attente',
      failed: 'Échoué',
    },
    documents_editor: {
      editor_workspace: "Espace de travail de l'éditeur: ",
      doc_name: 'Nom du document:   ',
      download_doc: 'Télécharger le document',
      find_replace: 'Trouver et Remplacer',
      last_edit: 'Dernière modification',
      edited: 'Modifié',
      source_text: 'Texte source',
      target_text: 'Texte cible',
      find_next: 'Trouver suivant',
      replace: 'Remplacer',
      replace_all: 'Tout',
      search: 'Rechercher dans le document',
      changes: 'Modifications dans la ligne',
      automatically: 'enregistré automatiquement',
      download_translation: 'Télécharger la traduction',
      download_source: "Télécharger l'original",
      find: 'Trouver...',
      replace_with: 'Remplacer par...',
      pdf: '.PDF',
      docx: '.DOCX',
      drag: 'Faire glisser pour déplacer la fenêtre',
    },
    modals: {
      char_limit: 'Limite de caractères:',
      char_info: 'Veuillez modifier le texte pour respecter la limite.',
      char_button: "Modifier l'entrée",
      language_error: 'Oups! Vous avez oublié de choisir une langue.',
      language_info: 'Sélectionnez votre langue cible pour commencer.',
      language_button: 'Sélectionner la langue',
      document_error: 'Oups! Il y a eu un problème avec la traduction.',
      document_info: 'Téléchargez un autre fichier et réessayez.',
      document_button: 'Réessayer',
    },
    footer: {
      privacy_policy: 'Politique de Confidentialité',
      imprint: 'Empreinte',
      terms_and_conditions: "Conditions générales d'utilisation",
      cookie_policy: 'Politique de Cookies',
      contact: 'Contact',
      legal_notice: 'Mentions légales',
      data_protection: 'Protection des données',
      contact: 'Contact',
    },
    glossary: {
      create_glossary: 'Créer un Glossaire',
      glossary_name: 'Nom du Glossaire',
      glossary_list: 'Liste des Glossaires',
      add_term: 'Ajouter un Terme',
      delete: 'Supprimer',
      import_csv: 'Importer CSV',
      export_csv: 'Exporter CSV',
      rename: 'Renommer',
      added_terms: 'Termes ajoutés',
      search_glossary: 'Rechercher des termes...',
      source_text: 'Langue source',
      target_text: 'Langue cible',
      enter_source: 'Entrez le texte source',
      enter_target: 'Entrez le texte cible',
      glossary: 'Glossaire',
      loading: 'Chargement des glossaires en cours...',
      entry_already_exists: 'L\'entrée existe déjà',
      empty_glossary: 'Aucune entrée trouvée dans le glossaire.',
      glossary_already_exists: 'Le glossaire existe déjà',
    },
    copyright: {
      copyright: '© Laniqo. Tous droits réservés.',
    },
    admin: {
      delete_user: "Supprimer l'utilisateur",
      create_user: 'Créer un Nouvel Utilisateur',
      new_user: 'Nouvel Utilisateur',
      submit: 'Soumettre',
      activate_user: "Activer l'utilisateur",
      activate_help: "Vérifiez si l'utilisateur doit être actif immédiatement après la création.",
      verify_user: "Vérifier l'email",
      verify_help: "Cochez la case si l'utilisateur doit vérifier son email.",
      search_email: 'Rechercher par email',
      required: 'Requis',
      characters: 'Doit contenir au moins 8 caractères.',
      invalid_email: 'Adresse email invalide.',
      first_name: 'Prénom',
      last_name: 'Nom de famille',
      email: 'Email',
      creation_date: 'Date de création',
      active: 'Actif',
      password: 'Mot de passe',
    },
    user_panel: {
      change_password: 'Changer le mot de passe',
      change_password_description:
        'Vous serez redirigé vers la page suivante pour changer votre mot de passe.',
      change_password_button: 'Changer le mot de passe',
      access_token: "Jeton d'Accès",
      access_token_description:
        "Les jetons d'accès authentifient l'identité de l'utilisateur dans l'API Laniqo MT et permettent aux applications d'effectuer des actions en fonction des autorisations du jeton.",
      api_key: 'Clé API',
      api_key_description: 'Clicquez sur le bouton pour générer la Clé',
      generate_button: 'Générer',
      profile: 'Profil',
      profile_description:
        'La section du profil contient des informations vous concernant. Vous pouvez les modifier et les mettre à jour.',
      email: 'Email',
      email_description: 'Entrez votre adresse email',
      first_name: 'Prénom',
      first_name_description: 'Entrez la première partie de votre prénom',
      last_name: 'Nom de famille',
      last_name_description: 'Entrez la dernière partie de votre nom de famille',
      update_button: 'Mettre à jour',
      contact_admin: "Contacter l'Admin",
      contact_admin_description:
        'Pour des problèmes plus complexes ou une assistance au-delà du support standard, veuillez contacter votre administrateur.',
      copy_icon_alt: 'Icône Copier',
      edit_icon_alt: 'Icône Modifier',
      input_new_email_placeholder: 'Entrez un nouvel email',
      enter_first_name_placeholder: 'Entrez le prénom',
      enter_last_name_placeholder: 'Entrez le nom de famille',
      update_info: "Les informations de l'utilisateur ont été modifiées!",
      open: 'Ouvrir le Panneau Admin',
    },
    pagination: {
      prev: 'Page précédente',
      next: 'Page suivante',
      first: 'Première page',
      last: 'Dernière page',
    },
  },
  uk: {
    notifications: {
      glossary_add_entry: 'Будь ласка, заповніть усі обов\'язкові поля.',
    },
    menu: {
      text_translator: 'Перекладач тексту',
      new_document: 'Перекладач документів',
      documents: 'Документи',
      last_document: 'Останній документ',
      document_editor: 'Редактор документів',
      language_correction: 'Полірування тексту',
      glossary: 'Глосарії',
      user_panel: 'Панель користувача',
      admin_panel: 'Адмін-панель',
      logout: 'Вийти',
      neutral: 'Neutral',
    },
    text_translator: {
      source_select: 'Виберіть мову джерела',
      target_select: 'Виберіть мову перекладу',
      detected_language: 'Виявлена мова',
      select_tone: 'Виберіть тон',
      select_domain: 'Виберіть домен',
      show_changes: 'Показати зміни:',
      select_language: 'Виберіть мову',
      search: 'Пошук',
      no_options: 'Немає варіантів',
      no_results: 'Немає результатів для: ',
      detect: 'Виявити Мову',
      placeholder: 'Введіть текст, який ви хочете перекласти...',
      placeholder_correction: 'Введіть текст, який ви хочете виправити...',
    },
    documents: {
      supported_documents: 'Підтримувані формати: ',
      drag_or_browse: 'Перетягніть і відпустіть або виберіть файл для завантаження',
      upload: 'Виберіть файл',
      upload_new: 'Додати новий файл',
      document_uploaded: 'Документ завантажено!',
      ready_translate: 'Ми готові перекласти ваш документ',
      translate: 'Перекласти',
      remove: 'Видалити',
      document_translated: 'Ваш документ перекладено!',
      download: 'Завантажити',
      document_name: 'Назва документа:',
      source_language: 'Мова джерела:',
      target_language: 'Мова перекладу:',
      actions: 'Дії:',
      max: 'Ви можете завантажити максимум 5 документів одночасно.',
      tone: 'Тон',
      domain: 'Домен',
      glossary: 'Глосарій',
      uploading: 'Завантаження файлу...',
      sending_server: 'Файл завантажено, відправка на сервер...',
      uploading_error: 'Ой! Щось пішло не так. Будь ласка, спробуйте ще раз.',
      uploading_error_large: 'Файл занадто великий. Максимальний розмір 100МБ.',
      document_ready: 'Документ готовий до перекладу.',
      document_translated: 'Документ успішно перекладено!',
      document_translating: 'Документ перекладається... {progress}%',
      detecting: 'Виявлення...',
      translating_warning: 'Недостатньо інформації для перекладу.',
      empty_documents: 'Ви не додали ще жодного документа.',
    },
    documents_viewer: {
      file_name: 'Назва файлу',
      from: 'Від',
      to: 'До',
      status: 'Статус',
      created: 'Створено',
      actions: 'Дії',
      add_document: 'Додати документ',
      delete_documents: 'Видалити документи',
      delete_document: 'Видалити документ',
      sure: 'Ви впевнені, що хочете видалити ',
      sure_documents: 'Ви впевнені, що хочете видалити вибрані документи?',
      delete: 'Видалити',
      search: 'Пошук за назвою документа',
      rows: 'Рядків на сторінку',
      no: 'Ні',
      yes: 'Так',
      edit: 'Редагувати',
      download: 'Завантажити',
      info: 'Інформація про документ:',
      file_type: 'Тип файлу:',
      chars_before: 'Символи до перекладу:',
      chars_after: 'Символи після перекладу:',
      translated: 'Перекладено',
      converted: 'Конвертовано',
      translating: 'Переклад',
      pending: 'Очікується',
      failed: 'Помилка',
    },
    documents_editor: {
      editor_workspace: 'Робоча область редактора: ',
      doc_name: 'Назва документа:   ',
      download_doc: 'Завантажити документ',
      find_replace: 'Знайти і замінити',
      last_edit: 'Останнє редагування',
      edited: 'Відредаговано',
      source_text: 'Текст джерела',
      target_text: 'Текст перекладу',
      find_next: 'Знайти наступне',
      replace: 'Замінити',
      replace_all: 'все',
      search: 'Пошук у документі',
      changes: 'Зміни в рядку',
      automatically: 'збережено автоматично',
      download_translation: 'Завантажити переклад',
      download_source: 'Завантажити оригінал',
      find: 'Знайти...',
      replace_with: 'Замінити на...',
      pdf: '.PDF',
      docx: '.DOCX',
      drag: 'Перетягніть, щоб перемістити вікно',
    },
    modals: {
      char_limit: 'Обмеження символів:',
      char_info: 'Будь ласка, відредагуйте текст, щоб він відповідав обмеженню.',
      char_button: 'Редагувати введення',
      language_error: 'Ой! Ви забули вибрати мову.',
      language_info: 'Виберіть мову перекладу, щоб почати.',
      language_button: 'Вибрати мову',
      document_error: 'Ой! Виникла проблема з перекладом.',
      document_info: 'Завантажте інший файл і спробуйте ще раз.',
      document_button: 'Спробувати ще раз',
    },
    footer: {
      privacy_policy: 'Політика конфіденційності',
      imprint: 'Вихідні дані',
      terms_and_conditions: 'Умови використання',
      cookie_policy: 'Політика використання файлів cookie',
      contact: 'Контакт',
      legal_notice: 'Юридичне повідомлення',
      data_protection: 'Захист даних',
      contact: 'Контакт',
    },
    glossary: {
      create_glossary: 'Створити глосарій',
      glossary_name: 'Назва глосарію',
      glossary_list: 'Λίστα Γλωσσαρίων',
      add_term: 'Додати термін',
      delete: 'Видалити',
      import_csv: 'Імпортувати CSV',
      export_csv: 'Експортувати CSV',
      rename: 'Перейменувати',
      added_terms: 'Додані терміни',
      search_glossary: 'Пошук терміна...',
      source_text: 'Мова джерела',
      target_text: 'Мова перекладу',
      enter_source: 'Введіть текст джерела',
      enter_target: 'Введіть текст перекладу',
      glossary: 'Глосарій',
      loading: 'Глосарії завантажуються...',
      entry_already_exists: 'Впис існує',
      empty_glossary: 'Немає записів у глосарії.',
      glossary_already_exists: 'Глосарій вже існує',
    },
    copyright: {
      copyright: '© Laniqo. Всі права захищені.',
    },
    admin: {
      delete_user: 'Видалити користувача',
      create_user: 'Створити нового користувача',
      new_user: 'Новий користувач',
      submit: 'Надіслати',
      activate_user: 'Активувати користувача',
      activate_help: 'Перевірте, чи повинен користувач бути активним відразу після створення.',
      verify_user: 'Перевірити електронну пошту',
      verify_help: 'Позначте поле, якщо користувач повинен перевірити свою електронну пошту.',
      search_email: 'Пошук за електронною поштою',
      required: 'Обов\'язково',
      characters: 'Має бути не менше 8 символів.',
      invalid_email: 'Недійсна електронна адреса.',
      first_name: 'Ім\'я',
      last_name: 'Прізвище',
      email: 'Електронна пошта',
      creation_date: 'Дата створення',
      active: 'Активний',
      password: 'Пароль',
    },
    user_panel: {
      change_password: 'Змінити пароль',
      change_password_description:
        'Ви будете перенаправлені на наступну сторінку для зміни пароля.',
      change_password_button: 'Змінити пароль',
      access_token: 'Токен доступу',
      access_token_description:
        'Токени доступу аутентифікують особу користувача в Laniqo MT API та дозволяють додаткам виконувати дії на основі дозволів токена.',
      api_key: 'API-ключ',
      api_key_description: 'Натисніть кнопку, щоб згенерувати ключ',
      generate_button: 'Генерувати',
      profile: 'Профіль',
      profile_description:
        'Розділ профілю містить інформацію про вас. Ви можете редагувати та оновлювати його.',
      email: 'Електронна пошта',
      email_description: 'Введіть свою електронну адресу',
      first_name: 'Ім\'я',
      first_name_description: 'Введіть першу частину свого імені',
      last_name: 'Прізвище',
      last_name_description: 'Введіть останню частину свого імені',
      update_button: 'Оновити',
      contact_admin: 'Зв\'язатися з адміністратором',
      contact_admin_description:
        'Для більш складних питань або допомоги, що виходить за межі стандартної підтримки, зверніться до адміністратора',
      copy_icon_alt: 'Іконка копіювання',
      edit_icon_alt: 'Іконка редагування',
      input_new_email_placeholder: 'Введіть нову електронну адресу',
      enter_first_name_placeholder: 'Введіть ім\'я',
      enter_last_name_placeholder: 'Введіть прізвище',
      update_info: 'Інформацію про користувача змінено!',
      open: 'Відкрити панель адміністратора',
    },
    pagination: {
      prev: 'Попередня сторінка',
      next: 'Наступна сторінка',
      first: 'Перша сторінка',
      last: 'Остання сторінка',
    },
  },
  it: {
    notifications: {
      glossary_add_entry: 'Si prega di compilare tutti i campi obbligatori.',
    },
    menu: {
      text_translator: 'Traduttore di Testo',
      new_document: 'Traduttore di Documenti',
      documents: 'Documenti',
      last_document: 'Ultimo Documento',
      document_editor: 'Editor di Documenti',
      language_correction: 'Correzione di Testo',
      glossary: 'Glossari',
      user_panel: 'Pannello Utente',
      admin_panel: 'Pannello Admin',
      logout: 'Logout',
      neutral: 'Neutrale',
    },
    text_translator: {
      source_select: 'Seleziona lingua di origine',
      target_select: 'Seleziona lingua di destinazione',
      detected_language: 'Lingua rilevata',
      select_tone: 'Seleziona Tono',
      select_domain: 'Seleziona Dominio',
      show_changes: 'Mostra modifiche:',
      select_language: 'Seleziona lingua',
      search: 'Cerca',
      no_options: 'Nessuna opzione',
      no_results: 'Nessun risultato per: ',
      detect: 'Rileva Lingua',
      placeholder: 'Inserisci il testo che vuoi tradurre...',
      placeholder_correction: 'Inserisci il testo che vuoi correggere...',
    },
    documents: {
      supported_documents: 'Formati supportati: ',
      drag_or_browse: 'Trascina e rilascia o scegli un file da caricare',
      upload: 'Scegli file',
      upload_new: 'Aggiungi nuovo file',
      document_uploaded: 'Documento caricato!',
      ready_translate: 'Siamo pronti a tradurre il tuo documento',
      translate: 'Traduci',
      remove: 'Rimuovi',
      document_translated: 'Il tuo documento è stato tradotto!',
      download: 'Scarica',
      document_name: 'Nome del documento:',
      source_language: 'Lingua di origine:',
      target_language: 'Lingua di destinazione:',
      actions: 'Azioni:',
      max: 'Puoi caricare un massimo di 5 documenti alla volta.',
      tone: 'Tono',
      domain: 'Dominio',
      glossary: 'Glossario',
      uploading: 'Caricamento del file...',
      sending_server: 'File caricato, invio al server...',
      uploading_error: 'Ops! Qualcosa è andato storto. Per favore riprova.',
      uploading_error_large: 'Il file è troppo grande. La dimensione massima è 100MB.',
      document_ready: 'Il documento è pronto per la traduzione.',
      document_translated: 'Il documento è stato tradotto con successo!',
      document_translating: 'Il documento è in fase di traduzione... {progress}%',
      detecting: 'Rilevamento...',
      translating_warning: 'Informazioni insufficienti per tradurre.',
      empty_documents: 'Non hai ancora aggiunto nessun documento.',
    },
    documents_viewer: {
      file_name: 'Nome del file',
      from: 'Da',
      to: 'A',
      status: 'Stato',
      created: 'Creato',
      actions: 'Azioni',
      add_document: 'Aggiungi documento',
      delete_documents: 'Elimina documenti',
      delete_document: 'Elimina documento',
      sure: 'Sei sicuro di voler eliminare ',
      sure_documents: 'Sei sicuro di voler eliminare i documenti selezionati?',
      delete: 'Elimina',
      search: 'Cerca per nome del documento',
      rows: 'Righe per pagina',
      no: 'No',
      yes: 'Sì',
      edit: 'Modifica',
      download: 'Scarica',
      info: 'Informazioni sul documento:',
      file_type: 'Tipo di file:',
      chars_before: 'Caratteri prima della traduzione:',
      chars_after: 'Caratteri dopo la traduzione:',
      translated: 'Tradotto',
      converted: 'Convertito',
      translating: 'Traduzione in corso',
      pending: 'In sospeso',
      failed: 'Fallito',
    },
    documents_editor: {
      editor_workspace: "Spazio di lavoro dell'editor: ",
      doc_name: 'Nome del documento:   ',
      download_doc: 'Scarica documento',
      find_replace: 'Trova e Sostituisci',
      last_edit: 'Ultima modifica',
      edited: 'Modificato',
      source_text: 'Testo di origine',
      target_text: 'Testo di destinazione',
      find_next: 'Trova successivo',
      replace: 'Sostituisci',
      replace_all: 'Tutto',
      search: 'Cerca nel documento',
      changes: 'Modifiche nella riga',
      automatically: 'salvato automaticamente',
      download_translation: 'Scarica traduzione',
      download_source: 'Scarica originale',
      find: 'Trova...',
      replace_with: 'Sostituisci con...',
      pdf: '.PDF',
      docx: '.DOCX',
      drag: 'Trascina per spostare la finestra',
    },
    modals: {
      char_limit: 'Limite di caratteri:',
      char_info: 'Modifica il testo per rientrare nel limite.',
      char_button: 'Modifica input',
      language_error: 'Ops! Hai dimenticato di scegliere una lingua.',
      language_info: 'Seleziona la tua lingua di destinazione per iniziare.',
      language_button: 'Seleziona lingua',
      document_error: "Ops! C'è stato un problema con la traduzione.",
      document_info: 'Carica un altro file e riprova.',
      document_button: 'Riprova',
    },
    footer: {
      privacy_policy: 'Politica sulla Privacy',
      imprint: 'Impronta',
      terms_and_conditions: "Termini e condizioni d'uso",
      cookie_policy: 'Politica sui Cookie',
      contact: 'Contatto',
      legal_notice: 'Avviso legale',
      data_protection: 'Protezione dei dati',
      contact: 'Contatto',
    },
    glossary: {
      create_glossary: 'Crea Glossario',
      glossary_name: 'Nome del Glossario',
      glossary_list: 'Elenco Glossari',
      add_term: 'Aggiungi Termine',
      delete: 'Elimina',
      import_csv: 'Importa CSV',
      export_csv: 'Esporta CSV',
      rename: 'Rinomina',
      added_terms: 'Termini aggiunti',
      search_glossary: 'Cerca termini...',
      source_text: 'Lingua di origine',
      target_text: 'Lingua di destinazione',
      enter_source: 'Inserisci testo di origine',
      enter_target: 'Inserisci testo di destinazione',
      glossary: 'Glossario',
      loading: 'Caricamento glossari in corso...',
      entry_already_exists: 'Termine già esistente',
      empty_glossary: 'Nessun termine trovato nel glossario.',
      glossary_already_exists: 'Glossario già esistente',
    },
    copyright: {
      copyright: '© Laniqo. Tutti i diritti riservati.',
    },
    admin: {
      delete_user: 'Elimina utente',
      create_user: 'Crea Nuovo Utente',
      new_user: 'Nuovo Utente',
      submit: 'Invia',
      activate_user: 'Attiva utente',
      activate_help: "Verifica se l'utente deve essere attivo immediatamente dopo la creazione.",
      verify_user: 'Verifica email',
      verify_help: "Seleziona la casella se l'utente deve verificare la propria email.",
      search_email: 'Cerca per email',
      required: 'Richiesto',
      characters: 'Deve essere di almeno 8 caratteri.',
      invalid_email: 'Indirizzo email non valido.',
      first_name: 'Nome',
      last_name: 'Cognome',
      email: 'Email',
      creation_date: 'Data di creazione',
      active: 'Attivo',
      password: 'Password',
    },
    user_panel: {
      change_password: 'Cambia password',
      change_password_description:
        'Verrai reindirizzato alla pagina successiva per cambiare la tua password.',
      change_password_button: 'Cambia password',
      access_token: 'Token di Accesso',
      access_token_description:
        "I token di accesso autenticano l'identità dell'utente nell'API Laniqo MT e consentono alle applicazioni di eseguire azioni in base alle autorizzazioni del token.",
      api_key: 'Chiave API',
      api_key_description: 'Clicca sul pulsante per generare la Chiave',
      generate_button: 'Genera',
      profile: 'Profilo',
      profile_description:
        'La sezione del profilo contiene informazioni su di te. Puoi modificarle e aggiornarle.',
      email: 'Email',
      email_description: 'Inserisci il tuo indirizzo email',
      first_name: 'Nome',
      first_name_description: 'Inserisci la prima parte del tuo nome',
      last_name: 'Cognome',
      last_name_description: "Inserisci l'ultima parte del tuo nome",
      update_button: 'Aggiorna',
      contact_admin: 'Contatta Admin',
      contact_admin_description:
        'Per problemi più complessi o assistenza oltre il supporto standard, contatta il tuo amministratore.',
      copy_icon_alt: 'Icona Copia',
      edit_icon_alt: 'Icona Modifica',
      input_new_email_placeholder: 'Inserisci nuova email',
      enter_first_name_placeholder: 'Inserisci nome',
      enter_last_name_placeholder: 'Inserisci cognome',
      update_info: "Le informazioni dell'utente sono state modificate!",
      open: 'Apri Pannello Admin',
    },
    pagination: {
      prev: 'Pagina precedente',
      next: 'Pagina successiva',
      first: 'Prima pagina',
      last: 'Ultima pagina',
    },
  },
  pt: {
    notifications: {
      glossary_add_entry: 'Por favor, preencha todos os campos obrigatórios.',
    },
    menu: {
      text_translator: 'Tradutor de Texto',
      new_document: 'Tradutor de Documentos',
      documents: 'Documentos',
      last_document: 'Último Documento',
      document_editor: 'Editor de Documentos',
      language_correction: 'Correção de Texto',
      glossary: 'Glossários',
      user_panel: 'Painel do Usuário',
      admin_panel: 'Painel de Administração',
      logout: 'Sair',
      neutral: 'Neutral',
    },
    text_translator: {
      source_select: 'Selecione a língua de origem',
      target_select: 'Selecione a língua de destino',
      detected_language: 'Língua detectada',
      select_tone: 'Selecione o Tom',
      select_domain: 'Selecione o Domínio',
      show_changes: 'Mostrar alterações:',
      select_language: 'Selecione a língua',
      search: 'Pesquisar',
      no_options: 'Sem opções',
      no_results: 'Nenhum resultado para: ',
      detect: 'Detectar Idioma',
      placeholder: 'Digite o texto que você deseja traduzir...',
      placeholder_correction: 'Digite o texto que você deseja corrigir...',
    },
    documents: {
      supported_documents: 'Formatos suportados: ',
      drag_or_browse: 'Arraste e solte ou escolha um arquivo para enviar',
      upload: 'Escolher arquivo',
      upload_new: 'Adicionar novo arquivo',
      document_uploaded: 'Documento enviado!',
      ready_translate: 'Estamos prontos para traduzir seu documento',
      translate: 'Traduzir',
      remove: 'Remover',
      document_translated: 'Seu documento foi traduzido!',
      download: 'Baixar',
      document_name: 'Nome do documento:',
      source_language: 'Língua de origem:',
      target_language: 'Língua de destino:',
      actions: 'Ações:',
      max: 'Você pode enviar um máximo de 5 documentos por vez.',
      tone: 'Tom',
      domain: 'Domínio',
      glossary: 'Glossário',
      uploading: 'Enviando o arquivo...',
      sending_server: 'Arquivo enviado, enviando para o servidor...',
      uploading_error: 'Ops! Algo deu errado. Por favor, tente novamente.',
      uploading_error_large: 'O arquivo é muito grande. O tamanho máximo é 100MB.',
      document_ready: 'O documento está pronto para tradução.',
      document_translated: 'O documento foi traduzido com sucesso!',
      document_translating: 'O documento está sendo traduzido... {progress}%',
      detecting: 'Detectando...',
      empty_documents: 'Você ainda não adicionou nenhum documento.',
    },
    documents_viewer: {
      file_name: 'Nome do arquivo',
      from: 'De',
      to: 'Para',
      status: 'Status',
      created: 'Criado',
      actions: 'Ações',
      add_document: 'Adicionar documento',
      delete_documents: 'Excluir documentos',
      delete_document: 'Excluir documento',
      sure: 'Tem certeza de que deseja excluir ',
      sure_documents: 'Tem certeza de que deseja excluir os documentos selecionados?',
      delete: 'Excluir',
      search: 'Pesquisar por nome do documento',
      rows: 'Linhas por página',
      no: 'Não',
      yes: 'Sim',
      edit: 'Editar',
      download: 'Baixar',
      info: 'Informações sobre o documento:',
      file_type: 'Tipo de arquivo:',
      chars_before: 'Caracteres antes da tradução:',
      chars_after: 'Caracteres após a tradução:',
      translated: 'Traduzido',
      converted: 'Convertido',
      translating: 'Traduzindo',
      pending: 'Pendente',
      failed: 'Falhou',
    },
    documents_editor: {
      editor_workspace: 'Espaço de Trabalho do Editor: ',
      doc_name: 'Nome do Documento:   ',
      download_doc: 'Baixar documento',
      find_replace: 'Encontrar e Substituir',
      last_edit: 'Última Edição',
      edited: 'Editado',
      source_text: 'Texto de origem',
      target_text: 'Texto de destino',
      find_next: 'Encontrar próximo',
      replace: 'Substituir',
      replace_all: 'Tudo',
      search: 'Pesquisar no documento',
      changes: 'Alterações na linha',
      automatically: 'salvo automaticamente',
      download_translation: 'Baixar tradução',
      download_source: 'Baixar original',
      find: 'Encontrar...',
      replace_with: 'Substituir por...',
      pdf: '.PDF',
      docx: '.DOCX',
      drag: 'Arrastar para mover a janela',
    },
    modals: {
      char_limit: 'Limite de caracteres:',
      char_info: 'Por favor, edite o texto para caber dentro do limite.',
      char_button: 'Editar entrada',
      language_error: 'Ops! Você esqueceu de escolher uma língua.',
      language_info: 'Selecione sua língua de destino para começar.',
      language_button: 'Selecionar língua',
      document_error: 'Ops! Houve um problema com a tradução.',
      document_info: 'Envie outro arquivo e tente novamente.',
      document_button: 'Tente novamente',
    },
    footer: {
      privacy_policy: 'Política de Privacidade',
      imprint: 'Impressão',
      terms_and_conditions: 'Termos e condições de uso',
      cookie_policy: 'Política de Cookies',
      contact: 'Contato',
      legal_notice: 'Aviso legal',
      data_protection: 'Proteção de dados',
      contact: 'Contato',
    },
    glossary: {
      create_glossary: 'Criar Glossário',
      glossary_name: 'Nome do Glossário',
      glossary_list: 'Lista de Glossários',
      add_term: 'Adicionar Termo',
      delete: 'Excluir',
      import_csv: 'Importar CSV',
      export_csv: 'Exportar CSV',
      rename: 'Renomear',
      added_terms: 'Termos adicionados',
      search_glossary: 'Pesquisar termos...',
      source_text: 'Língua de origem',
      target_text: 'Língua de destino',
      enter_source: 'Insira o texto de origem',
      enter_target: 'Insira o texto de destino',
      glossary: 'Glossário',
      loading: 'Carregando glossários...',
      entry_already_exists: 'Termo já existe',
      empty_glossary: 'Nenhum termo encontrado no glossário.',
      glossary_already_exists: 'Glossário já existe',
    },
    copyright: {
      copyright: '© Laniqo. Todos os direitos reservados.',
    },
    admin: {
      delete_user: 'Excluir usuário',
      create_user: 'Criar Novo Usuário',
      new_user: 'Novo Usuário',
      submit: 'Enviar',
      activate_user: 'Ativar usuário',
      activate_help: 'Verifique se o usuário deve estar ativo imediatamente após a criação.',
      verify_user: 'Verificar email',
      verify_help: 'Marque a caixa se o usuário precisar verificar seu email.',
      search_email: 'Pesquisar por email',
      required: 'Obrigatório',
      characters: 'Deve ter pelo menos 8 caracteres.',
      invalid_email: 'Endereço de email inválido.',
      first_name: 'Nome',
      last_name: 'Sobrenome',
      email: 'Email',
      creation_date: 'Data de criação',
      active: 'Ativo',
      password: 'Senha',
    },
    user_panel: {
      change_password: 'Mudar senha',
      change_password_description:
        'Você será redirecionado para a próxima página para alterar sua senha.',
      change_password_button: 'Mudar senha',
      access_token: 'Token de Acesso',
      access_token_description:
        'Os tokens de acesso autenticam a identidade do usuário na API Laniqo MT e permitem que os aplicativos realizem ações com base nas permissões do token.',
      api_key: 'Chave API',
      api_key_description: 'Clique no botão para gerar a Chave',
      generate_button: 'Gerar',
      profile: 'Perfil',
      profile_description:
        'A seção de perfil contém informações sobre você. Você pode editá-las e atualizá-las.',
      email: 'Email',
      email_description: 'Insira seu endereço de email',
      first_name: 'Nome',
      first_name_description: 'Insira a primeira parte do seu nome',
      last_name: 'Sobrenome',
      last_name_description: 'Insira a última parte do seu nome',
      update_button: 'Atualizar',
      contact_admin: 'Contatar Admin',
      contact_admin_description:
        'Para problemas mais complexos ou assistência além do suporte padrão, entre em contato com seu administrador.',
      copy_icon_alt: 'Ícone de Copiar',
      edit_icon_alt: 'Ícone de Editar',
      input_new_email_placeholder: 'Insira novo email',
      enter_first_name_placeholder: 'Insira o nome',
      enter_last_name_placeholder: 'Insira o sobrenome',
      update_info: 'As informações do usuário foram alteradas!',
      open: 'Abrir Painel Admin',
    },
    pagination: {
      prev: 'Página anterior',
      next: 'Próxima página',
      first: 'Primeira página',
      last: 'Última página',
    },
  },
  es: {
    notifications: {
      glossary_add_entry: 'Por favor, complete todos los campos obligatorios.',
    },
    menu: {
      text_translator: 'Traductor de Texto',
      new_document: 'Traductor de Documentos',
      documents: 'Documentos',
      last_document: 'Último Documento',
      document_editor: 'Editor de Documentos',
      language_correction: 'Corrección de Texto',
      glossary: 'Glosarios',
      user_panel: 'Panel de Usuario',
      admin_panel: 'Panel de Administración',
      logout: 'Cerrar sesión',
      neutral: 'Neutral',
    },
    text_translator: {
      source_select: 'Seleccionar idioma de origen',
      target_select: 'Seleccionar idioma de destino',
      detected_language: 'Idioma detectado',
      select_tone: 'Seleccionar Tono',
      select_domain: 'Seleccionar Dominio',
      show_changes: 'Mostrar cambios:',
      select_language: 'Seleccionar idioma',
      search: 'Buscar',
      no_options: 'Sin opciones',
      no_results: 'No hay resultados para: ',
      detect: 'Detectar Idioma',
      placeholder: 'Ingresa el texto que deseas traducir...',
      placeholder_correction: 'Ingresa el texto que deseas corregir...',
    },
    documents: {
      supported_documents: 'Formatos soportados: ',
      drag_or_browse: 'Arrastra y suelta o elige un archivo para subir',
      upload: 'Elegir archivo',
      upload_new: 'Agregar nuevo archivo',
      document_uploaded: '¡Documento subido!',
      ready_translate: 'Estamos listos para traducir tu documento',
      translate: 'Traducir',
      remove: 'Eliminar',
      document_translated: '¡Tu documento ha sido traducido!',
      download: 'Descargar',
      document_name: 'Nombre del documento:',
      source_language: 'Idioma de origen:',
      target_language: 'Idioma de destino:',
      actions: 'Acciones:',
      max: 'Puedes subir un máximo de 5 documentos a la vez.',
      tone: 'Tono',
      domain: 'Dominio',
      glossary: 'Glosario',
      uploading: 'Subiendo el archivo...',
      sending_server: 'Archivo subido, enviando al servidor...',
      uploading_error: '¡Ups! Algo salió mal. Por favor, inténtalo de nuevo.',
      uploading_error_large: 'El archivo es demasiado grande. El tamaño máximo es 100MB.',
      document_ready: 'El documento está listo para traducir.',
      document_translated: 'El documento ha sido traducido con éxito!',
      document_translating: 'El documento está siendo traducido... {progress}%',
      detecting: 'Detectando...',
      translating_warning: 'Información insuficiente para traducir.',
      empty_documents: 'No has añadido ningún documento todavía.',
    },
    documents_viewer: {
      file_name: 'Nombre del archivo',
      from: 'De',
      to: 'A',
      status: 'Estado',
      created: 'Creado',
      actions: 'Acciones',
      add_document: 'Agregar documento',
      delete_documents: 'Eliminar documentos',
      delete_document: 'Eliminar documento',
      sure: '¿Estás seguro de que deseas eliminar ',
      sure_documents: '¿Estás seguro de que deseas eliminar los documentos seleccionados?',
      delete: 'Eliminar',
      search: 'Buscar por nombre del documento',
      rows: 'Filas por página',
      no: 'No',
      yes: 'Sí',
      edit: 'Editar',
      download: 'Descargar',
      info: 'Información sobre el documento:',
      file_type: 'Tipo de archivo:',
      chars_before: 'Caracteres antes de la traducción:',
      chars_after: 'Caracteres después de la traducción:',
      translated: 'Traducido',
      converted: 'Convertido',
      translating: 'Traduciendo',
      pending: 'Pendiente',
      failed: 'Fallido',
    },
    documents_editor: {
      editor_workspace: 'Espacio de Trabajo del Editor: ',
      doc_name: 'Nombre del Documento:   ',
      download_doc: 'Descargar documento',
      find_replace: 'Buscar y Reemplazar',
      last_edit: 'Última Edición',
      edited: 'Editado',
      source_text: 'Texto de origen',
      target_text: 'Texto de destino',
      find_next: 'Buscar siguiente',
      replace: 'Reemplazar',
      replace_all: 'Todo',
      search: 'Buscar en el documento',
      changes: 'Cambios en la fila',
      automáticamente: 'guardado automáticamente',
      download_translation: 'Descargar traducción',
      download_source: 'Descargar original',
      find: 'Buscar...',
      replace_with: 'Reemplazar con...',
      pdf: '.PDF',
      docx: '.DOCX',
      drag: 'Arrastrar para mover la ventana',
    },
    modals: {
      char_limit: 'Límite de caracteres:',
      char_info: 'Por favor, edita el texto para ajustarlo al límite.',
      char_button: 'Editar entrada',
      language_error: '¡Ups! Olvidaste elegir un idioma.',
      language_info: 'Selecciona tu idioma de destino para comenzar.',
      language_button: 'Seleccionar idioma',
      document_error: '¡Ups! Hubo un problema con la traducción.',
      document_info: 'Sube otro archivo e inténtalo de nuevo.',
      document_button: 'Intentar de nuevo',
    },
    footer: {
      privacy_policy: 'Política de Privacidad',
      imprint: 'Imprint',
      terms_and_conditions: 'Términos y condiciones de uso',
      cookie_policy: 'Política de Cookies',
      contact: 'Contacto',
      legal_notice: 'Aviso legal',
      data_protection: 'Protección de datos',
      contact: 'Contacto',
    },
    glossary: {
      create_glossary: 'Crear Glosario',
      glossary_name: 'Nombre del Glosario',
      glossary_list: 'Lista de Glosarios',
      add_term: 'Agregar Término',
      delete: 'Eliminar',
      import_csv: 'Importar CSV',
      export_csv: 'Exportar CSV',
      rename: 'Renombrar',
      added_terms: 'Términos agregados',
      search_glossary: 'Buscar términos...',
      source_text: 'Idioma de origen',
      target_text: 'Idioma de destino',
      enter_source: 'Ingresa el texto de origen',
      enter_target: 'Ingresa el texto de destino',
      glossary: 'Glosario',
      loading: 'Cargando glosarios...',
      entry_already_exists: 'Entrada ya existe',
      empty_glossary: 'No hay entradas en el glosario.',
      glossary_already_exists: 'El glosario ya existe',
    },
    copyright: {
      copyright: '© Laniqo. Todos los derechos reservados.',
    },
    admin: {
      delete_user: 'Eliminar usuario',
      create_user: 'Crear Nuevo Usuario',
      new_user: 'Nuevo Usuario',
      submit: 'Enviar',
      activate_user: 'Activar usuario',
      activate_help:
        'Verifica si el usuario debe estar activo inmediatamente después de la creación.',
      verify_user: 'Verificar email',
      verify_help: 'Marca la casilla si el usuario necesita verificar su email.',
      search_email: 'Buscar por email',
      required: 'Requerido',
      characters: 'Debe tener al menos 8 caracteres.',
      invalid_email: 'Dirección de email inválida.',
      first_name: 'Nombre',
      last_name: 'Apellido',
      email: 'Email',
      creation_date: 'Fecha de creación',
      active: 'Activo',
      password: 'Contraseña',
    },
    user_panel: {
      change_password: 'Cambiar contraseña',
      change_password_description:
        'Serás redirigido a la siguiente página para cambiar tu contraseña.',
      change_password_button: 'Cambiar contraseña',
      access_token: 'Token de Acceso',
      access_token_description:
        'Los tokens de acceso autentican la identidad del usuario en la API Laniqo MT y permiten que las aplicaciones realicen acciones basadas en los permisos del token.',
      api_key: 'Clave API',
      api_key_description: 'Haz clic en el botón para generar la Clave',
      generate_button: 'Generar',
      profile: 'Perfil',
      profile_description:
        'La sección de perfil contiene información sobre ti. Puedes editarla y actualizarla.',
      email: 'Email',
      email_description: 'Ingresa tu dirección de email',
      first_name: 'Nombre',
      first_name_description: 'Ingresa la primera parte de tu nombre',
      last_name: 'Apellido',
      last_name_description: 'Ingresa la última parte de tu nombre',
      update_button: 'Actualizar',
      contact_admin: 'Contactar Admin',
      contact_admin_description:
        'Para problemas más complejos o asistencia más allá del soporte estándar, contacta a tu administrador.',
      copy_icon_alt: 'Icono de Copiar',
      edit_icon_alt: 'Icono de Editar',
      input_new_email_placeholder: 'Ingresa nuevo email',
      enter_first_name_placeholder: 'Ingresa el nombre',
      enter_last_name_placeholder: 'Ingresa el apellido',
      update_info: '¡La información del usuario ha sido cambiada!',
      open: 'Abrir Panel Admin',
    },
    pagination: {
      prev: 'Página anterior',
      next: 'Página siguiente',
      first: 'Primera página',
      last: 'Última página',
    },
  },
  tr: {
    notifications: {
      glossary_add_entry: 'Lütfen tüm gerekli alanları doldurun.',
    },
    menu: {
      text_translator: 'Metin Çevirici',
      new_document: 'Belge Çevirici',
      documents: 'Belgeler',
      last_document: 'Son Belge',
      document_editor: 'Belge Düzenleyici',
      language_correction: 'Metin Düzeltme',
      glossary: 'Sözlükler',
      user_panel: 'Kullanıcı Paneli',
      admin_panel: 'Yönetici Paneli',
      logout: 'Çıkış Yap',
      neutral: 'Neutral',
    },
    text_translator: {
      source_select: 'Kaynak dili seçin',
      target_select: 'Hedef dili seçin',
      detected_language: 'Tespit edilen dil',
      select_tone: 'Ton Seçin',
      select_domain: 'Alan Seçin',
      show_changes: 'Değişiklikleri göster:',
      select_language: 'Dil seçin',
      search: 'Ara',
      no_options: 'Seçenek yok',
      no_results: '"{query}" için sonuç yok.',
      detect: 'Dili Tespit Et',
      placeholder: 'Çevirmek istediğiniz metni girin...',
      placeholder_correction: 'Düzeltmek istediğiniz metni girin...',
    },
    documents: {
      supported_documents: 'Desteklenen formatlar: ',
      drag_or_browse: 'Yüklemek için sürükleyip bırakın veya dosya seçin',
      upload: 'Dosya Seç',
      upload_new: 'Yeni dosya ekle',
      document_uploaded: 'Belge yüklendi!',
      ready_translate: 'Belgenizi çevirmeye hazırız',
      translate: 'Çevir',
      remove: 'Kaldır',
      document_translated: 'Belgeniz çevrildi!',
      download: 'İndir',
      document_name: 'Belge adı:',
      source_language: 'Kaynak dil:',
      target_language: 'Hedef dil:',
      actions: 'Eylemler:',
      max: 'Aynı anda en fazla 5 belge yükleyebilirsiniz.',
      tone: 'Ton',
      domain: 'Alan',
      glossary: 'Sözlük',
      uploading: 'Dosya yükleniyor...',
      sending_server: 'Dosya yüklendi, sunucuya gönderiliyor...',
      uploading_error: 'Hata! Bir şeyler ters gitti. Lütfen tekrar deneyin.',
      uploading_error_large: 'Dosya boyutu 100MB\'yi aştı. Lütfen daha küçük bir dosya seçin.',
      document_ready: 'Belge çeviri için hazır.',
      document_translated: 'Belge başarıyla çevrildi!',
      document_translating: 'Belge çevriliyor... {progress}%',
      detecting: 'Tespit ediliyor...',
      translating_warning: 'Çevirmek için yetersiz bilgi.',
      empty_documents: 'Henüz hiçbir belge eklenmedi.',
    },
    documents_viewer: {
      file_name: 'Dosya adı',
      from: 'Kaynak',
      to: 'Hedef',
      status: 'Durum',
      created: 'Oluşturuldu',
      actions: 'Eylemler',
      add_document: 'Belge ekle',
      delete_documents: 'Belgeleri sil',
      delete_document: 'Belgeyi sil',
      sure: 'Silmek istediğinizden emin misiniz ',
      sure_documents: 'Seçilen belgeleri silmek istediğinizden emin misiniz?',
      delete: 'Sil',
      search: 'Belge adına göre ara',
      rows: 'Sayfa başına satır',
      no: 'Hayır',
      yes: 'Evet',
      edit: 'Düzenle',
      download: 'İndir',
      info: 'Belge hakkında bilgi:',
      file_type: 'Dosya türü:',
      chars_before: 'Çeviri öncesi karakterler:',
      chars_after: 'Çeviri sonrası karakterler:',
      translated: 'Çevrildi',
      converted: 'Dönüştürüldü',
      translating: 'Çevriliyor',
      pending: 'Beklemede',
      failed: 'Başarısız',
    },
    documents_editor: {
      editor_workspace: 'Düzenleyici Çalışma Alanı: ',
      doc_name: 'Belge Adı:   ',
      download_doc: 'Belgeyi indir',
      find_replace: 'Bul ve Değiştir',
      last_edit: 'Son Düzenleme',
      edited: 'Düzenlendi',
      source_text: 'Kaynak metin',
      target_text: 'Hedef metin',
      find_next: 'Sonrakini bul',
      replace: 'Değiştir',
      replace_all: 'Tümünü',
      search: 'Belgede ara',
      changes: 'Satırdaki değişiklikler',
      automatically: 'otomatik olarak kaydedildi',
      download_translation: 'Çeviriyi indir',
      download_source: 'Orijinali indir',
      find: 'Bul...',
      replace_with: 'Şununla değiştir...',
      pdf: '.PDF',
      docx: '.DOCX',
      drag: 'Pencereyi taşımak için sürükleyin',
    },
    modals: {
      char_limit: 'Karakter sınırı:',
      char_info: 'Metni sınır içine sığdırmak için düzenleyin.',
      char_button: 'Girişi düzenle',
      language_error: 'Hata! Dil seçmeyi unuttunuz.',
      language_info: 'Başlamak için hedef dilinizi seçin.',
      language_button: 'Dil seçin',
      document_error: 'Hata! Çeviri ile ilgili bir sorun oluştu.',
      document_info: 'Başka bir dosya yükleyin ve tekrar deneyin.',
      document_button: 'Tekrar deneyin',
    },
    footer: {
      privacy_policy: 'Gizlilik Politikası',
      imprint: 'Baskı',
      terms_and_conditions: 'Kullanım Şartları ve Koşulları',
      cookie_policy: 'Çerez Politikası',
      contact: 'İletişim',
      legal_notice: 'Yasal Uyarı',
      data_protection: 'Veri Koruma',
      contact: 'İletişim',
    },
    glossary: {
      create_glossary: 'Sözlük Oluştur',
      glossary_name: 'Sözlük Adı',
      glossary_list: 'Sözlük Listesi',
      add_term: 'Terim Ekle',
      delete: 'Sil',
      import_csv: 'CSV İçe Aktar',
      export_csv: 'CSV Dışa Aktar',
      rename: 'Yeniden Adlandır',
      added_terms: 'Eklenen terimler',
      search_glossary: 'Terimleri ara...',
      source_text: 'Kaynak dil',
      target_text: 'Hedef dil',
      enter_source: 'Kaynak metni girin',
      enter_target: 'Hedef metni girin',
      glossary: 'Sözlük',
      loading: 'Sözlükler yükleniyor...',
      entry_already_exists: 'Eintrag existiert bereits',
      empty_glossary: 'Keine Einträge im Glossar gefunden.',
      glossary_already_exists: 'Glossar existiert bereits',
    },
    copyright: {
      copyright: '© Laniqo. Tüm hakları saklıdır.',
    },
    admin: {
      delete_user: 'Kullanıcıyı sil',
      create_user: 'Yeni Kullanıcı Oluştur',
      new_user: 'Yeni Kullanıcı',
      submit: 'Gönder',
      activate_user: 'Kullanıcıyı etkinleştir',
      activate_help:
        'Kullanıcının oluşturulduktan hemen sonra aktif olup olmayacağını kontrol edin.',
      verify_user: 'E-postayı doğrula',
      verify_help: 'Kullanıcının e-postasını doğrulaması gerekiyorsa kutuyu işaretleyin.',
      search_email: 'E-posta ile ara',
      required: 'Gerekli',
      characters: 'En az 8 karakter olmalıdır.',
      invalid_email: 'Geçersiz e-posta adresi.',
      first_name: 'Ad',
      last_name: 'Soyad',
      email: 'E-posta',
      creation_date: 'Oluşturulma tarihi',
      active: 'Aktif',
      password: 'Şifre',
    },
    user_panel: {
      change_password: 'Şifre değiştir',
      change_password_description:
        'Şifrenizi değiştirmek için bir sonraki sayfaya yönlendiriliyorsunuz.',
      change_password_button: 'Şifre değiştir',
      access_token: 'Erişim Belirteci',
      access_token_description:
        "Erişim belirteçleri, Laniqo MT API'sinde kullanıcının kimliğini doğrular ve uygulamaların belirtecin izinlerine göre eylemler gerçekleştirmesine olanak tanır.",
      api_key: 'API Anahtarı',
      api_key_description: 'Anahtarı oluşturmak için düğmeye tıklayın',
      generate_button: 'Oluştur',
      profile: 'Profil',
      profile_description:
        'Profil bölümü sizinle ilgili bilgileri içerir. Düzenleyebilir ve güncelleyebilirsiniz.',
      email: 'E-posta',
      email_description: 'E-posta adresinizi girin',
      first_name: 'Ad',
      first_name_description: 'Adınızın ilk kısmını girin',
      last_name: 'Soyad',
      last_name_description: 'Adınızın son kısmını girin',
      update_button: 'Güncelle',
      contact_admin: 'Yönetici ile İletişime Geçin',
      contact_admin_description:
        'Daha karmaşık sorunlar veya standart desteğin ötesinde yardım için yöneticinizle iletişime geçin.',
      copy_icon_alt: 'Kopyalama Simgesi',
      edit_icon_alt: 'Düzenleme Simgesi',
      input_new_email_placeholder: 'Yeni e-posta girin',
      enter_first_name_placeholder: 'Adınızı girin',
      enter_last_name_placeholder: 'Soyadınızı girin',
      update_info: 'Kullanıcı bilgileri değiştirildi!',
      open: 'Yönetici Panelini Aç',
    },
    pagination: {
      prev: 'Önceki sayfa',
      next: 'Sonraki sayfa',
      first: 'İlk sayfa',
      last: 'Son sayfa',
    },
  },
  zh: {
    notifications: {
      glossary_add_entry: '请填写所有必填字段。',
    },
    menu: {
      text_translator: '文本翻译器',
      new_document: '文档翻译器',
      documents: '文档',
      last_document: '最后一个文档',
      document_editor: '文档编辑器',
      language_correction: '文本校正',
      glossary: '词汇表',
      user_panel: '用户面板',
      admin_panel: '管理员面板',
      logout: '登出',
      neutral: '中性',
    },
    text_translator: {
      source_select: '选择源语言',
      target_select: '选择目标语言',
      detected_language: '检测到的语言',
      select_tone: '选择语气',
      select_domain: '选择领域',
      show_changes: '显示更改：',
      select_language: '选择语言',
      search: '搜索',
      no_options: '没有选项',
      no_results: '没有找到:  的结果。',
      detect: '检测语言',
      placeholder: '输入您想翻译的文本...',
      placeholder_correction: '输入您想要纠正的文本...',
    },
    documents: {
      supported_documents: '支持的格式：',
      drag_or_browse: '拖放或选择文件上传',
      upload: '选择文件',
      upload_new: '添加新文件',
      document_uploaded: '文件已上传！',
      ready_translate: '我们准备好翻译您的文件',
      translate: '翻译',
      remove: '移除',
      document_translated: '您的文件已翻译！',
      download: '下载',
      document_name: '文件名：',
      source_language: '源语言：',
      target_language: '目标语言：',
      actions: '操作：',
      max: '您一次最多可以上传5个文件。',
      tone: '语气',
      domain: '领域',
      glossary: '词汇表',
      uploading: '文件上传中...',
      sending_server: '文件已上传，正在发送到服务器...',
      uploading_error: '糟糕！出了点问题。请再试一次。',
      uploading_error_large: '文件太大了。最大尺寸是100MB。',
      document_ready: '文件已准备好翻译。',
      document_translated: '文件已成功翻译！',
      document_translating: '文件正在翻译中... {progress}%',
      detecting: '检测中...',
      translating_warning: '翻译信息不足。',
      empty_documents: '您还没有添加任何文档。',
    },
    documents_viewer: {
      file_name: '文件名',
      from: '从',
      to: '到',
      status: '状态',
      created: '创建时间',
      actions: '操作',
      add_document: '添加文件',
      delete_documents: '删除文件',
      delete_document: '删除文件',
      sure: '您确定要删除 ',
      sure_documents: '您确定要删除选定的文件吗？',
      delete: '删除',
      search: '按文件名搜索',
      rows: '每页行数',
      no: '否',
      yes: '是',
      edit: '编辑',
      download: '下载',
      info: '文件信息：',
      file_type: '文件类型：',
      chars_before: '翻译前的字符数：',
      chars_after: '翻译后的字符数：',
      translated: '已翻译',
      converted: '已转换',
      translating: '翻译中',
      pending: '待处理',
      failed: '失败',
    },
    documents_editor: {
      editor_workspace: '编辑器工作区：',
      doc_name: '文件名：',
      download_doc: '下载文件',
      find_replace: '查找和替换',
      last_edit: '最后编辑',
      edited: '已编辑',
      source_text: '源文本',
      target_text: '目标文本',
      find_next: '查找下一个',
      replace: '替换',
      replace_all: '全部替换',
      search: '在文件中搜索',
      changes: '行中的更改',
      automatically: '自动保存',
      download_translation: '下载翻译',
      download_source: '下载原文',
      find: '查找...',
      replace_with: '替换为...',
      pdf: '.PDF',
      docx: '.DOCX',
      drag: '拖动以移动窗口',
    },
    modals: {
      char_limit: '字符限制：',
      char_info: '请编辑文本以适应限制。',
      char_button: '编辑输入',
      language_error: '糟糕！您忘记选择语言了。',
      language_info: '选择您的目标语言以开始。',
      language_button: '选择语言',
      document_error: '糟糕！翻译时出现问题。',
      document_info: '上传另一个文件并重试。',
      document_button: '重试',
    },
    footer: {
      privacy_policy: '隐私政策',
      imprint: '印记',
      terms_and_conditions: '使用条款和条件',
      cookie_policy: 'Cookie政策',
      contact: '联系',
      legal_notice: '法律声明',
      data_protection: '数据保护',
      contact: '联系',
    },
    glossary: {
      create_glossary: '创建词汇表',
      glossary_name: '词汇表名称',
      glossary_list: '词汇表列表',
      add_term: '添加术语',
      delete: '删除',
      import_csv: '导入CSV',
      export_csv: '导出CSV',
      rename: '重命名',
      added_terms: '添加的术语',
      search_glossary: '搜索术语...',
      source_text: '源语言',
      target_text: '目标语言',
      enter_source: '输入源文本',
      enter_target: '输入目标文本',
      glossary: '词汇表',
      loading: '词汇表加载中...',
      entry_already_exists: '条目已存在',
      empty_glossary: '词汇表中没有条目。',
      glossary_already_exists: '词汇表已存在',
    },
    copyright: {
      copyright: '© Laniqo. 保留所有权利。',
    },
    admin: {
      delete_user: '删除用户',
      create_user: '创建新用户',
      new_user: '新用户',
      submit: '提交',
      activate_user: '激活用户',
      activate_help: '检查用户是否应在创建后立即激活。',
      verify_user: '验证电子邮件',
      verify_help: '如果用户需要验证其电子邮件，请选中该框。',
      search_email: '按电子邮件搜索',
      required: '必填',
      characters: '必须至少包含8个字符。',
      invalid_email: '无效的电子邮件地址。',
      first_name: '名字',
      last_name: '姓氏',
      email: '电子邮件',
      creation_date: '创建日期',
      active: '活跃',
      password: '密码',
    },
    user_panel: {
      change_password: '更改密码',
      change_password_description: '您将被重定向到下一页以更改您的密码。',
      change_password_button: '更改密码',
      access_token: '访问令牌',
      access_token_description:
        '访问令牌在Laniqo MT API中验证用户的身份，并允许应用程序根据令牌的权限执行操作。',
      api_key: 'API密钥',
      api_key_description: '点击按钮生成密钥',
      generate_button: '生成',
      profile: '个人资料',
      profile_description: '个人资料部分包含有关您的信息。您可以编辑和更新它。',
      email: '电子邮件',
      email_description: '输入您的电子邮件地址',
      first_name: '名字',
      first_name_description: '输入名字的第一部分',
      last_name: '姓氏',
      last_name_description: '输入名字的最后一部分',
      update_button: '更新',
      contact_admin: '联系管理员',
      contact_admin_description: '对于更复杂的问题或超出标准支持的帮助，请联系您的管理员。',
      copy_icon_alt: '复制图标',
      edit_icon_alt: '编辑图标',
      input_new_email_placeholder: '输入新电子邮件',
      enter_first_name_placeholder: '输入名字',
      enter_last_name_placeholder: '输入姓氏',
      update_info: '用户信息已更改！',
      open: '打开管理员面板',
    },
    pagination: {
      prev: '上一页',
      next: '下一页',
      first: '第一页',
      last: '最后一页',
    },
  },
  sk: {
    notifications: {
      glossary_add_entry: 'Vyplňte prosím všetky povinné polia.',
    },
    menu: {
      text_translator: 'Prekladač Textu',
      new_document: 'Prekladač Dokumentov',
      documents: 'Dokumenty',
      last_document: 'Posledný Dokument',
      document_editor: 'Editor Dokumentov',
      language_correction: 'Korekcia Textu',
      glossary: 'Glosáre',
      user_panel: 'Používateľský Panel',
      admin_panel: 'Admin Panel',
      logout: 'Odhlásiť sa',
      neutral: 'Neutral',
    },
    text_translator: {
      source_select: 'Vyberte zdrojový jazyk',
      target_select: 'Vyberte cieľový jazyk',
      detected_language: 'Detekovaný jazyk',
      select_tone: 'Vyberte Tón',
      select_domain: 'Vyberte Doménu',
      show_changes: 'Zobraziť zmeny:',
      select_language: 'Vyberte jazyk',
      search: 'Hľadať',
      no_options: 'Žiadne možnosti',
      no_results: 'Žiadne výsledky pre: ',
      detect: 'Detekovať Jazyk',
      placeholder: 'Zadajte text, ktorý chcete preložiť...',
      placeholder_correction: 'Zadajte text, ktorý chcete opraviť...',
    },
    documents: {
      supported_documents: 'Podporované formáty: ',
      drag_or_browse: 'Pretiahnite a pustite alebo vyberte súbor na nahranie',
      upload: 'Vybrať súbor',
      upload_new: 'Pridať nový súbor',
      document_uploaded: 'Dokument nahraný!',
      ready_translate: 'Sme pripravení preložiť váš dokument',
      translate: 'Preložiť',
      remove: 'Odstrániť',
      document_translated: 'Váš dokument bol preložený!',
      download: 'Stiahnuť',
      document_name: 'Názov dokumentu:',
      source_language: 'Zdrojový jazyk:',
      target_language: 'Cieľový jazyk:',
      actions: 'Akcie:',
      max: 'Môžete nahrať maximálne 5 dokumentov naraz.',
      tone: 'Tón',
      domain: 'Doména',
      glossary: 'Glosár',
      uploading: 'Nahrávanie súboru...',
      sending_server: 'Súbor nahraný, odosielanie na server...',
      uploading_error: 'Ups! Niečo sa pokazilo. Skúste to prosím znova.',
      uploading_error_large: 'Plik je príliš veľký. Maximálna veľkosť je 100MB.',
      document_ready: 'Dokument je pripravený na preklad.',
      document_translated: 'Dokument bol úspešne preložený!',
      document_translating: 'Dokument sa prekladá... {progress}%',
      detecting: 'Detekcia...',
      translating_warning: 'Nedostatok informácií na preklad.',
      empty_documents: 'Nie ste pridané žiadne dokumenty.',
    },
    documents_viewer: {
      file_name: 'Názov súboru',
      from: 'Od',
      to: 'Do',
      status: 'Stav',
      created: 'Vytvorené',
      actions: 'Akcie',
      add_document: 'Pridať dokument',
      delete_documents: 'Usuň dokumenty',
      delete_document: 'Usuň dokument',
      sure: 'Ste si istí, že chcete odstrániť ',
      sure_documents: 'Ste si istí, že chcete odstrániť vybrané dokumenty?',
      delete: 'Odstrániť',
      search: 'Hľadať podľa názvu dokumentu',
      rows: 'Riadkov na stránku',
      no: 'Nie',
      yes: 'Áno',
      edit: 'Upraviť',
      download: 'Pobierz',
      info: 'Informácie o dokumencie:',
      file_type: 'Typ súboru:',
      chars_before: 'Znaky pred prekladom:',
      chars_after: 'Znaky po preklade:',
      translated: 'Preložené',
      converted: 'Konvertované',
      translating: 'Prekladá sa',
      pending: 'Čaká sa',
      failed: 'Zlyhalo',
    },
    documents_editor: {
      editor_workspace: 'Pracovný Priestor Editora: ',
      doc_name: 'Názov Dokumentu:   ',
      download_doc: 'Stiahnuť dokument',
      find_replace: 'Nájsť a Nahradiť',
      last_edit: 'Posledná Úprava',
      edited: 'Upravené',
      source_text: 'Zdrojový text',
      target_text: 'Cieľový text',
      find_next: 'Nájsť ďalšie',
      replace: 'Nahradiť',
      replace_all: 'Všetko',
      search: 'Hľadať v dokumente',
      changes: 'Zmeny v riadku',
      automatically: 'automaticky uložené',
      download_translation: 'Stiahnuť preklad',
      download_source: 'Stiahnuť originál',
      find: 'Nájsť...',
      replace_with: 'Nahradiť s...',
      pdf: '.PDF',
      docx: '.DOCX',
      drag: 'Potiahnutím presuniete okno',
    },
    modals: {
      char_limit: 'Limit znakov:',
      char_info: 'Upravte text, aby sa zmestil do limitu.',
      char_button: 'Upraviť vstup',
      language_error: 'Ups! Zabudli ste vybrať jazyk.',
      language_info: 'Vyberte cieľový jazyk, aby ste mohli začať.',
      language_button: 'Vyberte jazyk',
      document_error: 'Ups! Vyskytol sa problém s prekladom.',
      document_info: 'Nahrajte iný súbor a skúste to znova.',
      document_button: 'Skúsiť znova',
    },
    footer: {
      privacy_policy: 'Zásady ochrany osobných údajov',
      imprint: 'Imprint',
      terms_and_conditions: 'Podmienky používania',
      cookie_policy: 'Zásady používania súborov cookie',
      contact: 'Kontakt',
      legal_notice: 'Právne oznámenie',
      data_protection: 'Ochrana údajov',
      contact: 'Kontakt',
    },
    glossary: {
      create_glossary: 'Vytvoriť Glosár',
      glossary_name: 'Názov Glosára',
      glossary_list: 'Zoznam Glosárov',
      add_term: 'Pridať Termín',
      delete: 'Odstrániť',
      import_csv: 'Importovať CSV',
      export_csv: 'Exportovať CSV',
      rename: 'Premenovať',
      added_terms: 'Pridané termíny',
      search_glossary: 'Hľadať termíny...',
      source_text: 'Zdrojový jazyk',
      target_text: 'Cieľový jazyk',
      enter_source: 'Zadajte zdrojový text',
      enter_target: 'Zadajte cieľový text',
      glossary: 'Glosár',
      loading: 'Načítavajú sa glosáre...',
      entry_already_exists: 'Vpis juž existuje',
      empty_glossary: 'Nie sú v glosárii žiadne položky.',
      glossary_already_exists: 'Glosár už existuje',
    },
    copyright: {
      copyright: '© Laniqo. Všetky práva vyhradené.',
    },
    admin: {
      delete_user: 'Odstrániť používateľa',
      create_user: 'Vytvoriť Nového Používateľa',
      new_user: 'Nový Používateľ',
      submit: 'Odoslať',
      activate_user: 'Aktivovať používateľa',
      activate_help: 'Skontrolujte, či má byť používateľ aktívny ihneď po vytvorení.',
      verify_user: 'Overiť email',
      verify_help: 'Začiarknite políčko, ak používateľ potrebuje overiť svoj email.',
      search_email: 'Hľadať podľa emailu',
      required: 'Povinné',
      characters: 'Musí mať aspoň 8 znakov.',
      invalid_email: 'Neplatná emailová adresa.',
      first_name: 'Meno',
      last_name: 'Priezvisko',
      email: 'Email',
      creation_date: 'Dátum vytvorenia',
      active: 'Aktívny',
      password: 'Heslo',
    },
    user_panel: {
      change_password: 'Zmeniť heslo',
      change_password_description:
        'Budete presmerovaní na ďalšiu stránku, aby ste zmenili svoje heslo.',
      change_password_button: 'Zmeniť heslo',
      access_token: 'Prístupový Token',
      access_token_description:
        'Prístupové tokeny overujú identitu používateľa v Laniqo MT API a umožňujú aplikáciám vykonávať akcie na základe povolení tokenu.',
      api_key: 'API Kľúč',
      api_key_description: 'Kliknite na tlačidlo na generovanie Kľúča',
      generate_button: 'Generovať',
      profile: 'Profil',
      profile_description:
        'Sekcia profilu obsahuje informácie o vás. Môžete ich upraviť a aktualizovať.',
      email: 'Email',
      email_description: 'Zadajte svoju emailovú adresu',
      first_name: 'Meno',
      first_name_description: 'Zadajte prvú časť svojho mena',
      last_name: 'Priezvisko',
      last_name_description: 'Zadajte poslednú časť svojho mena',
      update_button: 'Aktualizovať',
      contact_admin: 'Kontaktovať Admina',
      contact_admin_description:
        'Pre zložitejšie problémy alebo pomoc nad rámec štandardnej podpory kontaktujte svojho administrátora.',
      copy_icon_alt: 'Ikona Kopírovania',
      edit_icon_alt: 'Ikona Úpravy',
      input_new_email_placeholder: 'Zadajte nový email',
      enter_first_name_placeholder: 'Zadajte meno',
      enter_last_name_placeholder: 'Zadajte priezvisko',
      update_info: 'Informácie o používateľovi boli zmenené!',
      open: 'Otvoriť Admin Panel',
    },
    pagination: {
      prev: 'Predchádzajúca strana',
      next: 'Nasledujúca strana',
      first: 'Prvá strana',
      last: 'Posledná strana',
    },
  },
  el: {
    notifications: {
      glossary_add_entry: 'Παρακαλώ συμπληρώστε όλα τα υποχρεωτικά πεδία.',
    },
    menu: {
      text_translator: 'Μεταφραστής Κειμένου',
      new_document: 'Μεταφραστής Εγγράφων',
      documents: 'Έγγραφα',
      last_document: 'Τελευταίο Έγγραφο',
      document_editor: 'Επεξεργαστής Εγγράφων',
      language_correction: 'Διόρθωση Κειμένου',
      glossary: 'Γλωσσάρια',
      user_panel: 'Πίνακας Χρήστη',
      admin_panel: 'Πίνακας Διαχειριστή',
      logout: 'Αποσύνδεση',
      neutral: 'Ουδέτερο'
    },
    text_translator: {
      source_select: 'Επιλέξτε γλώσσα πηγής',
      target_select: 'Επιλέξτε γλώσσα στόχου',
      detected_language: 'Ανιχνευμένη γλώσσα',
      select_tone: 'Επιλέξτε Τόνο',
      select_domain: 'Επιλέξτε Τομέα',
      show_changes: 'Εμφάνιση αλλαγών:',
      select_language: 'Επιλέξτε γλώσσα',
      search: 'Αναζήτηση',
      no_options: 'Δεν υπάρχουν επιλογές',
      no_results: 'Δεν βρέθηκαν αποτελέσματα για: ',
      detect: 'Ανίχνευση Γλώσσας',
      placeholder: 'Εισαγάγετε το κείμενο που θέλετε να μεταφράσετε...',
      placeholder_correction: 'Εισαγάγετε το κείμενο που θέλετε να διορθώσετε...',
    },
    documents: {
      supported_documents: 'Υποστηριζόμενες μορφές: ',
      drag_or_browse: 'Σύρετε και αποθέστε ή επιλέξτε ένα αρχείο για μεταφόρτωση',
      upload: 'Επιλέξτε αρχείο',
      upload_new: 'Προσθήκη νέου αρχείου',
      document_uploaded: 'Το έγγραφο μεταφορτώθηκε!',
      ready_translate: 'Είμαστε έτοιμοι να μεταφράσουμε το έγγραφό σας',
      translate: 'Μετάφραση',
      remove: 'Αφαίρεση',
      document_translated: 'Το έγγραφό σας έχει μεταφραστεί!',
      download: 'Λήψη',
      document_name: 'Όνομα εγγράφου:',
      source_language: 'Γλώσσα προέλευσης:',
      target_language: 'Γλώσσα στόχος:',
      actions: 'Ενέργειες:',
      max: 'Μπορείτε να μεταφορτώσετε έως και 5 έγγραφα ταυτόχρονα.',
      tone: 'Τόνος',
      domain: 'Τομέας',
      glossary: 'Γλωσσάριο',
      uploading: 'Μεταφόρτωση αρχείου...',
      sending_server: 'Το αρχείο μεταφορτώθηκε, αποστολή στον διακομιστή...',
      uploading_error: 'Ουπς! Κάτι πήγε στραβά. Παρακαλώ δοκιμάστε ξανά.',
      uploading_error_large: 'Ο χώρος αποθήκευσης είναι πλήρης. Η μέγιστη δυνατή χωρητικότητα είναι 100MB.',
      document_ready: 'Το έγγραφο είναι έτοιμο για μετάφραση.',
      document_translated: 'Το έγγραφο μεταφράστηκε με επιτυχία!',
      document_translating: 'Το έγγραφο μεταφράζεται... {progress}%',
      detecting: 'Ανίχνευση...',
      translating_warning: 'Ανεπαρκείς πληροφορίες για μετάφραση.',
      empty_documents: 'Δεν έχετε προσθέσει ακόμη οποιοδήποτε έγγραφο.',
    },
    documents_viewer: {
      file_name: 'Όνομα αρχείου',
      from: 'Από',
      to: 'Προς',
      status: 'Κατάσταση',
      created: 'Δημιουργήθηκε',
      actions: 'Ενέργειες',
      add_document: 'Προσθήκη εγγράφου',
      delete_documents: 'Διαγραφή εγγράφων',
      delete_document: 'Διαγραφή εγγράφου',
      sure: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε ',
      sure_documents: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε τα επιλεγμένα έγγραφα;',
      delete: 'Διαγραφή',
      search: 'Αναζήτηση κατά όνομα εγγράφου',
      rows: 'Γραμμές ανά σελίδα',
      no: 'Όχι',
      yes: 'Ναι',
      edit: 'Επεξεργασία',
      download: 'Λήψη',
      info: 'Πληροφορίες για το έγγραφο:',
      file_type: 'Τύπος αρχείου:',
      chars_before: 'Χαρακτήρες πριν τη μετάφραση:',
      chars_after: 'Χαρακτήρες μετά τη μετάφραση:',
      translated: 'Μεταφρασμένο',
      converted: 'Μετατράπηκε',
      translating: 'Μεταφράζεται',
      pending: 'Σε εκκρεμότητα',
      failed: 'Απέτυχε',
    },
    documents_editor: {
      editor_workspace: 'Χώρος Εργασίας Επεξεργαστή: ',
      doc_name: 'Όνομα Εγγράφου:   ',
      download_doc: 'Λήψη εγγράφου',
      find_replace: 'Εύρεση και Αντικατάσταση',
      last_edit: 'Τελευταία Επεξεργασία',
      edited: 'Επεξεργασμένο',
      source_text: 'Κείμενο πηγής',
      target_text: 'Κείμενο στόχου',
      find_next: 'Εύρεση επόμενου',
      replace: 'Αντικατάσταση',
      replace_all: 'Όλων',
      search: 'Αναζήτηση στο έγγραφο',
      changes: 'Αλλαγές στη γραμμή',
      automatically: 'αποθηκεύτηκε αυτόματα',
      download_translation: 'Λήψη μετάφρασης',
      download_source: 'Λήψη πρωτοτύπου',
      find: 'Εύρεση...',
      replace_with: 'Αντικατάσταση με...',
      pdf: '.PDF',
      docx: '.DOCX',
      drag: 'Σύρετε για να μετακινήσετε το παράθυρο',
    },
    modals: {
      char_limit: 'Όριο χαρακτήρων:',
      char_info: 'Επεξεργαστείτε το κείμενο για να χωρέσει στο όριο.',
      char_button: 'Επεξεργασία εισόδου',
      language_error: 'Ουπς! Ξεχάσατε να επιλέξετε γλώσσα.',
      language_info: 'Επιλέξτε τη γλώσσα στόχου σας για να ξεκινήσετε.',
      language_button: 'Επιλέξτε γλώσσα',
      document_error: 'Ουπς! Υπήρξε πρόβλημα με τη μετάφραση.',
      document_info: 'Ανεβάστε άλλο αρχείο και δοκιμάστε ξανά.',
      document_button: 'Δοκιμάστε ξανά',
    },
    footer: {
      privacy_policy: 'Πολιτική Απορρήτου',
      imprint: 'Αποτύπωμα',
      terms_and_conditions: 'Όροι και Προϋποθέσεις Χρήσης',
      cookie_policy: 'Πολιτική Cookies',
      contact: 'Επικοινωνία',
      legal_notice: 'Νομική Ειδοποίηση',
      data_protection: 'Προστασία Δεδομένων',
      contact: 'Επικοινωνία',
    },
    glossary: {
      create_glossary: 'Δημιουργία Γλωσσαρίου',
      glossary_name: 'Όνομα Γλωσσαρίου',
      glossary_list: 'Λίστα Γλωσσαρίων',
      add_term: 'Προσθήκη Όρου',
      delete: 'Διαγραφή',
      import_csv: 'Εισαγωγή CSV',
      export_csv: 'Εξαγωγή CSV',
      rename: 'Μετονομασία',
      added_terms: 'Προστέθηκαν όροι',
      search_glossary: 'Αναζήτηση όρων...',
      source_text: 'Γλώσσα προέλευσης',
      target_text: 'Γλώσσα στόχος',
      enter_source: 'Εισάγετε κείμενο πηγής',
      enter_target: 'Εισάγετε κείμενο στόχου',
      glossary: 'Γλωσσάριο',
      loading: 'Φόρτωση γλωσσαρίων...',
      entry_already_exists: 'Είσοδος υπάρχει',
      empty_glossary: 'Δεν βρέθηκαν στοιχεία στον λεξικό.',
      glossary_already_exists: 'Το λεξικό υπάρχει',
    },
    copyright: {
      copyright: '© Laniqo. Με επιφύλαξη παντός δικαιώματος.',
    },
    admin: {
      delete_user: 'Διαγραφή χρήστη',
      create_user: 'Δημιουργία Νέου Χρήστη',
      new_user: 'Νέος Χρήστης',
      submit: 'Υποβολή',
      activate_user: 'Ενεργοποίηση χρήστη',
      activate_help: 'Ελέγξτε αν ο χρήστης πρέπει να είναι ενεργός αμέσως μετά τη δημιουργία.',
      verify_user: 'Επαλήθευση email',
      verify_help: 'Επιλέξτε το πλαίσιο αν ο χρήστης πρέπει να επαληθεύσει το email του.',
      search_email: 'Αναζήτηση με email',
      required: 'Απαιτείται',
      characters: 'Πρέπει να έχει τουλάχιστον 8 χαρακτήρες.',
      invalid_email: 'Μη έγκυρη διεύθυνση email.',
      first_name: 'Όνομα',
      last_name: 'Επώνυμο',
      email: 'Email',
      creation_date: 'Ημερομηνία δημιουργίας',
      active: 'Ενεργός',
      password: 'Κωδικός πρόσβασης',
    },
    user_panel: {
      change_password: 'Αλλαγή κωδικού πρόσβασης',
      change_password_description:
        'Θα ανακατευθυνθείτε στην επόμενη σελίδα για να αλλάξετε τον κωδικό πρόσβασής σας.',
      change_password_button: 'Αλλαγή κωδικού πρόσβασης',
      access_token: 'Διακριτικό Πρόσβασης',
      access_token_description:
        'Τα διακριτικά πρόσβασης πιστοποιούν την ταυτότητα του χρήστη στο API Laniqo MT και επιτρέπουν στις εφαρμογές να εκτελούν ενέργειες βάσει των δικαιωμάτων του διακριτικού.',
      api_key: 'Κλειδί API',
      api_key_description: 'Κάντε κλικ στο κουμπί για να δημιουργήσετε το Κλειδί',
      generate_button: 'Δημιουργία',
      profile: 'Προφίλ',
      profile_description:
        'Η ενότητα προφίλ περιέχει πληροφορίες για εσάς. Μπορείτε να τις επεξεργαστείτε και να τις ενημερώσετε.',
      email: 'Email',
      email_description: 'Εισάγετε τη διεύθυνση email σας',
      first_name: 'Όνομα',
      first_name_description: 'Εισάγετε το πρώτο μέρος του ονόματός σας',
      last_name: 'Επώνυμο',
      last_name_description: 'Εισάγετε το τελευταίο μέρος του ονόματός σας',
      update_button: 'Ενημέρωση',
      contact_admin: 'Επικοινωνία με Διαχειριστή',
      contact_admin_description:
        'Για πιο σύνθετα ζητήματα ή βοήθεια πέρα από την τυπική υποστήριξη, επικοινωνήστε με τον διαχειριστή σας.',
      copy_icon_alt: 'Εικονίδιο Αντιγραφής',
      edit_icon_alt: 'Εικονίδιο Επεξεργασίας',
      input_new_email_placeholder: 'Εισάγετε νέο email',
      enter_first_name_placeholder: 'Εισάγετε όνομα',
      enter_last_name_placeholder: 'Εισάγετε επώνυμο',
      update_info: 'Οι πληροφορίες χρήστη έχουν αλλάξει!',
      open: 'Άνοιγμα Πίνακα Διαχειριστή',
    },
    pagination: {
      prev: 'Προηγούμενη σελίδα',
      next: 'Επόμενη σελίδα',
      first: 'Πρώτη σελίδα',
      last: 'Τελευταία σελίδα',
    },
  },
  ru: {
    notifications: {
      glossary_add_entry: 'Пожалуйста, заполните все обязательные поля.',
    },
    menu: {
      text_translator: 'Переводчик Текста',
      new_document: 'Переводчик Документов',
      documents: 'Документы',
      last_document: 'Последний Документ',
      document_editor: 'Редактор Документов',
      language_correction: 'Коррекция Текста',
      glossary: 'Глоссарии',
      user_panel: 'Панель Пользователя',
      admin_panel: 'Админ Панель',
      logout: 'Выйти',
    neutral: 'Нейтральный',
    },
    text_translator: {
      source_select: 'Выберите исходный язык',
      target_select: 'Выберите целевой язык',
      detected_language: 'Обнаруженный язык',
      select_tone: 'Выберите Тон',
      select_domain: 'Выберите Домен',
      show_changes: 'Показать изменения:',
      select_language: 'Выберите язык',
      search: 'Поиск',
      no_options: 'Нет опций',
      no_results: 'Нет результатов для: ',
      detect: 'Обнаружить Язык',
      placeholder: 'Введите текст, который вы хотите перевести...',
      placeholder_correction: 'Введите текст, который вы хотите исправить...',
    },
    documents: {
      supported_documents: 'Поддерживаемые форматы: ',
      drag_or_browse: 'Перетащите и отпустите или выберите файл для загрузки',
      upload: 'Выбрать файл',
      upload_new: 'Добавить новый файл',
      document_uploaded: 'Документ загружен!',
      ready_translate: 'Мы готовы перевести ваш документ',
      translate: 'Перевести',
      remove: 'Удалить',
      document_translated: 'Ваш документ переведен!',
      download: 'Скачать',
      document_name: 'Имя документа:',
      source_language: 'Исходный язык:',
      target_language: 'Целевой язык:',
      actions: 'Действия:',
      max: 'Вы можете загрузить максимум 5 документов за раз.',
      tone: 'Тон',
      domain: 'Домен',
      glossary: 'Глоссарий',
      uploading: 'Загрузка файла...',
      sending_server: 'Файл загружен, отправка на сервер...',
      uploading_error: 'Упс! Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
      uploading_error_large: 'Файл занадто великий. Максимальный розмір 100МБ.',
      document_ready: 'Документ готов к переводу.',
      document_translated: 'Документ успешно переведен!',
      document_translating: 'Документ переводится... {progress}%',
      detecting: 'Обнаружение...',
      translating_warning: 'Недостаточно информации для перевода.',
      empty_documents: 'Вы еще не добавили ни одного документа.',
    },
    documents_viewer: {
      file_name: 'Имя файла',
      from: 'От',
      to: 'До',
      status: 'Статус',
      created: 'Создано',
      actions: 'Действия',
      add_document: 'Добавить документ',
      delete_documents: 'Удалить документы',
      delete_document: 'Удалить документ',
      sure: 'Вы уверены, что хотите удалить ',
      sure_documents: 'Вы уверены, что хотите удалить выбранные документы?',
      delete: 'Удалить',
      search: 'Поиск по имени документа',
      rows: 'Строк на страницу',
      no: 'Нет',
      yes: 'Да',
      edit: 'Редактировать',
      download: 'Скачать',
      info: 'Информация о документе:',
      file_type: 'Тип файла:',
      chars_before: 'Символов до перевода:',
      chars_after: 'Символов после перевода:',
      translated: 'Переведено',
      converted: 'Преобразовано',
      translating: 'Переводится',
      pending: 'В ожидании',
      failed: 'Не удалось',
    },
    documents_editor: {
      editor_workspace: 'Рабочее Пространство Редактора: ',
      doc_name: 'Имя Документа:   ',
      download_doc: 'Скачать документ',
      find_replace: 'Найти и Заменить',
      last_edit: 'Последнее Изменение',
      edited: 'Изменено',
      source_text: 'Исходный текст',
      target_text: 'Целевой текст',
      find_next: 'Найти следующее',
      replace: 'Заменить',
      replace_all: 'Все',
      search: 'Поиск в документе',
      changes: 'Изменения в строке',
      автоматически: 'сохранено автоматически',
      download_translation: 'Скачать перевод',
      download_source: 'Скачать оригинал',
      find: 'Найти...',
      replace_with: 'Заменить на...',
      pdf: '.PDF',
      docx: '.DOCX',
      drag: 'Перетащите, чтобы переместить окно',
    },
    modals: {
      char_limit: 'Лимит символов:',
      char_info: 'Пожалуйста, отредактируйте текст, чтобы он соответствовал лимиту.',
      char_button: 'Редактировать ввод',
      language_error: 'Упс! Вы забыли выбрать язык.',
      language_info: 'Выберите целевой язык, чтобы начать.',
      language_button: 'Выберите язык',
      document_error: 'Упс! Возникла проблема с переводом.',
      document_info: 'Загрузите другой файл и попробуйте снова.',
      document_button: 'Попробовать снова',
    },
    footer: {
      privacy_policy: 'Политика конфиденциальности',
      imprint: 'Импринт',
      terms_and_conditions: 'Условия использования',
      cookie_policy: 'Политика использования файлов cookie',
      contact: 'Контакт',
      legal_notice: 'Юридическое уведомление',
      data_protection: 'Защита данных',
      contact: 'Контакт',
    },
    glossary: {
      create_glossary: 'Создать Глоссарий',
      glossary_name: 'Имя Глоссария',
      glossary_list: 'Список Глоссариев',
      add_term: 'Добавить Термин',
      delete: 'Удалить',
      import_csv: 'Импортировать CSV',
      export_csv: 'Экспортировать CSV',
      rename: 'Переименовать',
      added_terms: 'Добавленные термины',
      search_glossary: 'Поиск терминов...',
      source_text: 'Исходный язык',
      target_text: 'Целевой язык',
      enter_source: 'Введите исходный текст',
      enter_target: 'Введите целевой текст',
      glossary: 'Глоссарий',
      loading: 'Загрузка глоссариев...',
      entry_already_exists: 'Впис иснует',
      empty_glossary: 'Не найдено записей в глоссарии.',
      glossary_already_exists: 'Глоссарий уже существует',
    },
    copyright: {
      copyright: '© Laniqo. Все права защищены.',
    },
    admin: {
      delete_user: 'Удалить пользователя',
      create_user: 'Создать Нового Пользователя',
      new_user: 'Новый Пользователь',
      submit: 'Отправить',
      activate_user: 'Активировать пользователя',
      activate_help: 'Проверьте, должен ли пользователь быть активным сразу после создания.',
      verify_user: 'Подтвердить email',
      verify_help: 'Отметьте поле, если пользователю нужно подтвердить свой email.',
      search_email: 'Поиск по email',
      required: 'Обязательно',
      characters: 'Должно быть не менее 8 символов.',
      invalid_email: 'Неверный адрес электронной почты.',
      first_name: 'Имя',
      last_name: 'Фамилия',
      email: 'Email',
      creation_date: 'Дата создания',
      active: 'Активный',
      password: 'Пароль',
    },
    user_panel: {
      change_password: 'Изменить пароль',
      change_password_description:
        'Вы будете перенаправлены на следующую страницу для изменения пароля.',
      change_password_button: 'Изменить пароль',
      access_token: 'Токен Доступа',
      access_token_description:
        'Токены доступа аутентифицируют личность пользователя в Laniqo MT API и позволяют приложениям выполнять действия на основе разрешений токена.',
      api_key: 'API Ключ',
      api_key_description: 'Нажмите кнопку для генерации Ключа',
      generate_button: 'Генерировать',
      profile: 'Профиль',
      profile_description:
        'Раздел профиля содержит информацию о вас. Вы можете редактировать и обновлять её.',
      email: 'Email',
      email_description: 'Введите свой адрес электронной почты',
      first_name: 'Имя',
      first_name_description: 'Введите первую часть своего имени',
      last_name: 'Фамилия',
      last_name_description: 'Введите последнюю часть своего имени',
      update_button: 'Обновить',
      contact_admin: 'Связаться с Администратором',
      contact_admin_description:
        'Для более сложных вопросов или помощи, выходящей за рамки стандартной поддержки, свяжитесь с вашим администратором.',
      copy_icon_alt: 'Иконка Копирования',
      edit_icon_alt: 'Иконка Редактирования',
      input_new_email_placeholder: 'Введите новый email',
      enter_first_name_placeholder: 'Введите имя',
      enter_last_name_placeholder: 'Введите фамилию',
      update_info: 'Информация о пользователе изменена!',
      open: 'Открыть Админ Панель',
    },
    pagination: {
      prev: 'Предыдущая страница',
      next: 'Следующая страница',
      first: 'Первая страница',
      last: 'Последняя страница',
    },
  },
  bg: {
    notifications: {
      glossary_add_entry: 'Моля, попълнете всички задължителни полета.',
    },
    menu: {
      text_translator: 'Преводач на Текст',
      new_document: 'Преводач на Документи',
      documents: 'Документи',
      last_document: 'Последен Документ',
      document_editor: 'Редактор на Документи',
      language_correction: 'Корекция на Текст',
      glossary: 'Речници',
      user_panel: 'Панел на Потребителя',
      admin_panel: 'Админ Панел',
      logout: 'Изход',
      neutral: 'Нейтрален',
    },
    text_translator: {
      source_select: 'Изберете изходен език',
      target_select: 'Изберете целеви език',
      detected_language: 'Открит език',
      select_tone: 'Изберете Тон',
      select_domain: 'Изберете Домейн',
      show_changes: 'Показване на промените:',
      select_language: 'Изберете език',
      search: 'Търсене',
      no_options: 'Няма опции',
      no_results: 'Няма резултати за: ',
      detect: 'Откриване На Език',
      placeholder: 'Въведете текста, който искате да преведете...',
      placeholder_correction: 'Въведете текста, който искате да коригирате...',
    },
    documents: {
      supported_documents: 'Поддържани формати: ',
      drag_or_browse: 'Плъзнете и пуснете или изберете файл за качване',
      upload: 'Изберете файл',
      upload_new: 'Добавяне на нов файл',
      document_uploaded: 'Документът е качен!',
      ready_translate: 'Готови сме да преведем вашия документ',
      translate: 'Превод',
      remove: 'Премахване',
      document_translated: 'Вашият документ е преведен!',
      download: 'Изтегляне',
      document_name: 'Име на документа:',
      source_language: 'Изходен език:',
      target_language: 'Целеви език:',
      actions: 'Действия:',
      max: 'Можете да качите максимум 5 документа наведнъж.',
      tone: 'Тон',
      domain: 'Домейн',
      glossary: 'Речник',
      uploading: 'Качване на файла...',
      sending_server: 'Файлът е качен, изпращане към сървъра...',
      uploading_error: 'Упс! Нещо се обърка. Моля, опитайте отново.',
      uploading_error_large: 'Файлът е прекалено голям. Максималният размер е 100MB.',
      document_ready: 'Документът е готов за превод.',
      document_translated: 'Документът е успешно преведен!',
      document_translating: 'Документът се превежда... {progress}%',
      detecting: 'Откриване...',
      translating_warning: 'Недостатъчно информация за превод.',
      empty_documents: 'Все още не сте добавили никакви документи.',
    },
    documents_viewer: {
      file_name: 'Име на файла',
      from: 'От',
      to: 'До',
      status: 'Статус',
      created: 'Създадено',
      actions: 'Действия',
      add_document: 'Добавяне на документ',
      delete_documents: 'Изтриване на документи',
      delete_document: 'Изтриване на документ',
      sure: 'Сигурни ли сте, че искате да изтриете ',
      sure_documents: 'Сигурни ли сте, че искате да изтриете избраните документи?',
      delete: 'Изтриване',
      search: 'Търсене по име на документ',
      rows: 'Редове на страница',
      no: 'Не',
      yes: 'Да',
      edit: 'Редактиране',
      download: 'Изтегляне',
      info: 'Информация за документа:',
      file_type: 'Тип на файла:',
      chars_before: 'Знаци преди превода:',
      chars_after: 'Знаци след превода:',
      translated: 'Преведено',
      converted: 'Конвертирано',
      translating: 'Превежда се',
      pending: 'В очакване',
      failed: 'Неуспешно',
    },
    documents_editor: {
      editor_workspace: 'Работно Пространство на Редактора: ',
      doc_name: 'Име на Документа:   ',
      download_doc: 'Изтегляне на документа',
      find_replace: 'Намиране и Замяна',
      last_edit: 'Последна Редакция',
      edited: 'Редактирано',
      source_text: 'Изходен текст',
      target_text: 'Целеви текст',
      find_next: 'Намиране на следващото',
      replace: 'Замяна',
      replace_all: 'Всички',
      search: 'Търсене в документа',
      changes: 'Промени в реда',
      automatically: 'автоматично запазено',
      download_translation: 'Изтегляне на превода',
      download_source: 'Изтегляне на оригинала',
      find: 'Намиране...',
      replace_with: 'Замяна с...',
      pdf: '.PDF',
      docx: '.DOCX',
      drag: 'Плъзнете, за да преместите прозореца',
    },
    modals: {
      char_limit: 'Ограничение на знаците:',
      char_info: 'Моля, редактирайте текста, за да се вмести в ограничението.',
      char_button: 'Редактиране на входа',
      language_error: 'Упс! Забравихте да изберете език.',
      language_info: 'Изберете целевия си език, за да започнете.',
      language_button: 'Изберете език',
      document_error: 'Упс! Възникна проблем с превода.',
      document_info: 'Качете друг файл и опитайте отново.',
      document_button: 'Опитайте отново',
    },
    footer: {
      privacy_policy: 'Политика за Поверителност',
      imprint: 'Импринт',
      terms_and_conditions: 'Условия за ползване',
      cookie_policy: 'Политика за бисквитки',
      contact: 'Контакт',
      legal_notice: 'Правно Уведомление',
      data_protection: 'Защита на данните',
      contact: 'Контакт',
    },
    glossary: {
      create_glossary: 'Създаване на Речник',
      glossary_name: 'Име на Речника',
      glossary_list: 'Списък на Речниците',
      add_term: 'Добавяне на Термин',
      delete: 'Изтриване',
      import_csv: 'Импортиране на CSV',
      export_csv: 'Експортиране на CSV',
      rename: 'Преименуване',
      added_terms: 'Добавени термини',
      search_glossary: 'Търсене на термини...',
      source_text: 'Изходен език',
      target_text: 'Целеви език',
      enter_source: 'Въведете изходен текст',
      enter_target: 'Въведете целеви текст',
      glossary: 'Речник',
      loading: 'Зареждане на речниците...',
      entry_already_exists: 'Въпис съществува',
      empty_glossary: 'Не са намерени записи в речника.',
      glossary_already_exists: 'Речникът съществува',
    },
    copyright: {
      copyright: '© Laniqo. Всички права запазени.',
    },
    admin: {
      delete_user: 'Изтриване на потребител',
      create_user: 'Създаване на Нов Потребител',
      new_user: 'Нов Потребител',
      submit: 'Изпращане',
      activate_user: 'Активиране на потребител',
      activate_help: 'Проверете дали потребителят трябва да бъде активен веднага след създаването.',
      verify_user: 'Потвърдете email',
      verify_help: 'Отметнете полето, ако потребителят трябва да потвърди своя email.',
      search_email: 'Търсене по email',
      required: 'Задължително',
      characters: 'Трябва да бъде поне 8 знака.',
      invalid_email: 'Невалиден email адрес.',
      first_name: 'Име',
      last_name: 'Фамилия',
      email: 'Email',
      creation_date: 'Дата на създаване',
      active: 'Активен',
      password: 'Парола',
    },
    user_panel: {
      change_password: 'Смяна на парола',
      change_password_description:
        'Ще бъдете пренасочени към следващата страница, за да промените паролата си.',
      change_password_button: 'Смяна на парола',
      access_token: 'Токен за Достъп',
      access_token_description:
        'Токените за достъп удостоверяват самоличността на потребителя в Laniqo MT API и позволяват на приложенията да изпълняват действия въз основа на разрешенията на токена.',
      api_key: 'API Ключ',
      api_key_description: 'Кликнете върху бутона, за да генерирате Ключа',
      generate_button: 'Генериране',
      profile: 'Профил',
      profile_description:
        'Секцията на профила съдържа информация за вас. Можете да я редактирате и актуализирате.',
      email: 'Email',
      email_description: 'Въведете своя email адрес',
      first_name: 'Име',
      first_name_description: 'Въведете първата част на името си',
      last_name: 'Фамилия',
      last_name_description: 'Въведете последната част на името си',
      update_button: 'Актуализиране',
      contact_admin: 'Свържете се с Администратора',
      contact_admin_description:
        'За по-сложни въпроси или помощ извън стандартната поддръжка, свържете се с вашия администратор.',
      copy_icon_alt: 'Икона за Копиране',
      edit_icon_alt: 'Икона за Редактиране',
      input_new_email_placeholder: 'Въведете нов email',
      enter_first_name_placeholder: 'Въведете име',
      enter_last_name_placeholder: 'Въведете фамилия',
      update_info: 'Информацията за потребителя е променена!',
      open: 'Отворете Админ Панела',
    },
    pagination: {
      prev: 'Предишна страница',
      next: 'Следваща страница',
      first: 'Първа страница',
      last: 'Последна страница',
    },
  },
}
