import VueKeycloak from '@dsb-norge/vue-keycloak-js'

export default defineNuxtPlugin(async (nuxtApp) => {
  const config_values = useRuntimeConfig();
  const keycloak = await new Promise((resolve, reject) => {
    nuxtApp.vueApp.use(VueKeycloak, {
      init: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
        checkLoginIframe: false,
      },
      config: {
        url: config_values.public.keycloakUrl,
        realm: config_values.public.keycloakRealm,
        clientId: config_values.public.keycloakClientId,
      },
      onReady: (kc) => {
        // console.log('Keycloak is ready:', kc)
        if (kc.authenticated && kc.tokenParsed?.realm_access?.roles?.includes('no_access')) {
          kc.logout();
          reject(new Error('User has no access rights'));
          return;
        }
        resolve(kc);
      },
      onInitError: (error) => {
        console.error('Keycloak init error:', error)
        reject(error)
      },
    })
  })

  nuxtApp.provide('keycloak', keycloak)
})