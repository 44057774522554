export default defineAppConfig({
  ui: {
    strategy: 'override',
    colors: ['customPrimary', 'redProgressBar'],
    primary: 'customPrimary',
    notifications: {
      wrapper: 'fixed flex flex-col justify-end z-[55]',
      position: 'top-0 end-0',
      width: 'w-full sm:w-96',
      container: 'px-2 sm:px-6 py-3 space-y-3 overflow-y-auto',
    },
    notification: {
      wrapper: 'w-full pointer-events-auto',
      container: 'relative overflow-hidden',
      inner: 'w-0 flex-1',
      title: 'text-sm font-medium text-black]',
      description: 'mt-1 text-sm leading-4 text-gray-500',
      descriptionOnly: 'mt-0 leading-5',
      actions: 'flex items-center gap-2 mt-3 flex-shrink-0',
      background: 'bg-whiteColor',
      shadow: 'shadow-lg',
      rounded: 'rounded-lg',
      padding: 'py-2 pl-3 pr-1',
      gap: 'gap-3',
      ring: 'ring-1 ring-gray-200',
      icon: {
        base: 'flex-shrink-0 w-5 h-5',
        color: 'text-{color}-500',
      },
      avatar: {
        base: 'flex-shrink-0 self-center',
        size: 'md',
      },
      progress: {
        base: '',
        background: '',
      },
      transition: {
        enterActiveClass: 'transform ease-out duration-300 transition',
        enterFromClass: 'translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2',
        enterToClass: 'translate-y-0 opacity-100 sm:translate-x-0',
        leaveActiveClass: 'transition ease-in duration-100',
        leaveFromClass: 'opacity-100',
        leaveToClass: 'opacity-0',
      },
      default: {
        color: 'primary',
        icon: null,
        timeout: 2000,
        closeButton: {
          icon: 'i-heroicons-x-mark-20-solid',
          color: 'black',
          variant: 'link',
          padded: false,
        },
        actionButton: {
          size: 'xs',
          color: 'white',
        },
      },
    },

    progress: {
      color: 'customPrimary',
      progress: {
        track: '[&::-webkit-progress-bar]:bg-gray-200',
      },
    },
    checkbox: {
      label: 'text-sm font-medium text-gray-700',
      base: 'cursor-pointer z-20 h-4 w-4 transition-colors duration-300',
      border: 'border-gray-300',
      ring: 'ring-offset-0 ring-1 ring-[#D1D1D6] rounded-sm hover:ring-[#AEAEB2] checked:hover:ring-[#00408F] focus:hover:ring-[#AEAEB2] focus:checked:hover:ring-[#00408F] checked:ring-mainColor focus:ring-1 focus:ring-[#D1D1D6] checked:focus:ring-mainColor checked:ring-offset-0 hover:ring-offset-0 focus:ring-offset-0 transition-colors duration-100',
      background:
        'bg-inherit checked:bg-mainColor checked:hover:bg-[#00408F] transition-colors duration-300',
    },
    toggle: {
      size: {
        md: 'h-[1.5rem] w-[3.5rem]',
      },
      active: 'bg-{color}-500',
      inactive: 'bg-gray-200',
      container: {
        base: 'pointer-events-none relative inline-block rounded-full bg-whiteColor shadow transform ring-0 transition ease-in-out duration-200',
        active: {
          md: 'translate-x-4 rtl:-translate-x-3.5',
        },
        inactive: '-translate-x-4 rtl:-translate-x-3.5',
        size: {
          md: 'h-[1.25rem] w-[1.25rem]',
        },
      },
    },
    table: {
      wrapper: 'scrollbar-gutter-stable relative max-h-[inherit] h-full overflow-y-auto rounded-[0.375rem_0.375rem_0rem_0rem]',
      base: 'min-w-full',
      divide: '',
      thead: 'relative bg-backgroundColor p-0 sticky top-0 z-50 shadow-[0_1px_0_0_darkGray]',
      tbody: 'p-0 z-0 max-h-[inherit]',
      tr: {
        base: ' ',
        selected: 'bg-gray-50',
        expanded: 'bg-gray-50',
        active: 'hover:bg-gray-50 cursor-pointer',
      },
      th: {
        base: 'text-left not-italic leading-[normal] text-left items-center',
        padding: 'py-[0.25rem] pl-[1.25rem]',
        color: 'text-customGray text-base',
        font: 'font-bold',
        size: 'text-md',
      },
      td: {
        base: 'whitespace-nowrap',
        padding: 'pl-[1.25rem]',
        color: 'text-customGray text-base',
        font: '',
        size: 'text-md',
      },
      loadingState: {
        wrapper: 'flex flex-col items-center justify-center flex-1 px-6 py-14 sm:px-14',
        label: 'text-sm text-center text-gray-900',
        icon: 'w-6 h-6 mx-auto text-gray-400 mb-4 animate-spin',
      },
      emptyState: {
        wrapper: 'flex flex-col items-center justify-center flex-1 px-6 py-14 sm:px-14',
        label: 'text-sm text-center text-gray-900',
        icon: 'w-6 h-6 mx-auto text-gray-400 mb-4',
      },
      progress: {
        wrapper: 'absolute inset-x-0 -bottom-[0.5px] p-0',
      },
      default: {
        sortAscIcon: 'i-heroicons-bars-arrow-up-20-solid',
        sortDescIcon: 'i-heroicons-bars-arrow-down-20-solid',
        sortButton: {
          icon: 'i-heroicons-arrows-up-down-20-solid',
          trailing: true,
          square: true,
          color: 'white',
          variant: 'ghost',
          class:
            'px-0 bg-backgroundColor hover:bg-transparent text-md text-customGray text-base font-bold',
        },
        progress: {
          color: 'primary',
          animation: 'carousel',
        },
        loadingState: {
          icon: 'i-heroicons-arrow-path-20-solid',
          label: 'Loading...',
        },
        emptyState: {
          icon: 'i-heroicons-circle-stack-20-solid',
          label: '',
        },
      },
    },
    dropdown: {
      wrapper: ' relative inline-flex',
      base: ' relative focus:outline-none overflow-y-auto scroll-py-1 mr-auto',
      container: 'z-40 group',
      trigger: 'inline-flex w-full',
      width: 'w-40',
      ring: 'ring-1 ring-gray-200',
      background: 'bg-whiteColor',
      shadow: '',
      padding: 'p-1',
      item: {
        base: 'font-normal bg-whiteColor hover:bg-lightHover dark:bg-whiteColor group flex justify-start  gap-1.5 w-full',
        disabled: 'cursor-auto opacity-100 hover:bg-whiteColor text-left ',
        size: 'text-md',
        active: 'bg-whiteColor text-mainColor',
        inactive: 'text-mainColor',
        disabled: 'cursor-auto opacity-100 hover:bg-whiteColor text-left ',
        icon: {
          base: 'flex-shrink-0 w-5 h-5',
          active: 'text-mainColor',
          inactive: 'text-mainColor',
        },
        avatar: {
          base: 'flex-shrink-0',
          size: '2xs',
        },
        label: 'truncate',
        shortcuts: 'hidden md:inline-flex flex-shrink-0 gap-0.5 ms-auto',
      },
    },
    select: {
      wrapper: 'relative',
      base: 'cursor-pointer bg-whiteColor dark:bg-whiteColor relative flex justify-start w-[10rem] disabled:hover:bg-backgroundColor disabled:cursor-not-allowed',
      placeholder: 'flex justify-start',
      size: {
        '2xs': 'text-xs',
        xs: 'text-xs h-[2rem]',
        sm: 'text-sm h-[2.5rem]',
        md: 'text-sm',
        lg: 'text-sm',
        xl: 'text-base',
      },
      color: {
        white: {
          none:
            'cursor-pointer font-normal shadow-sm bg-whiteColor hover:bg-lightHover disabled:hover:bg-whiteColor text-mainColor',
          outline:
            'ring-1 ring-red-400 text-red-400 font-normal shadow-sm bg-whiteColor hover:bg-lightHover disabled:hover:bg-whiteColor'
        },
        gray: {
          none:
            'cursor-pointer font-normal shadow-sm bg-backgroundColor hover:bg-lightHover disabled:hover:bg-backgroundColor disabled:cursor-not-allowed text-mainColor ring-0 ring-inset ring-gray-300 focus:ring-0 focus:ring-primary-500',
        },
      },
      variant: {
        outline:
          'ring-1 ring-inset ring-{color}-500 focus:ring-2 focus:ring-{color}-500',
        none: 'bg-transparent focus:ring-0 focus:shadow-none',
      },
      icon: {
        base: 'flex-shrink-0 text-mainColor',
      },
    },
    menu: {
      option: {
        base: 'hover:bg-lightHover cursor-pointer select-none relative flex items-center justify-between gap-1',
        container: 'flex items-center gap-1.5 min-w-0',
        active: 'bg-whiteColor text-mainColor',
        inactive: 'bg-whiteColor',
        disabled: 'cursor-not-allowed opacity-50',
        empty: 'text-sm text-gray-400  px-2 py-1.5',
        selectedIcon: {
          wrapper: 'invisible absolute inset-y-0 end-0 flex items-center',
          padding: 'pe-2',
          base: 'h-5 w-5 text-gray-900 flex-shrink-0',
        },
      },
      default: {
        selectedIcon: '',
        clearSearchOnClose: false,
        showCreateOptionWhen: 'empty',
        searchablePlaceholder: {
          label: 'Search...',
        },
        empty: {
          label: 'No options.',
        },
        optionEmpty: {
          label: 'No results for "{query}".',
        },
      },
    },
    modal: {
      background: 'bg-lightHover',
      overlay: {
        background: 'bg-gray-200/75',
      },
    },
    pagination: {
      wrapper: 'flex items-center  last:border-r-0',
      base: '',
      rounded: 'rounded-2',
      default: {
        size: 'sm',
        activeButton: {
          class: 'bg-lightHover hover:bg-mainColor   hover:text-whiteColor text-mainColor rounded-none ring-0 border-t border-b border-r border-gray-300',
          size: 'xs'
        },
        inactiveButton: {
          class: 'bg-whiteColor hover:bg-mainColor hover:text-whiteColor text-mainColor rounded-none ring-0 border-t border-b border-r border-gray-300',
          size: 'xs'
        },
        firstButton: {
          class: 'bg-whiteColor border-none ring-1 shadow-none hover:bg-mainColor   hover:text-whiteColor',
          icon: 'i-heroicons-chevron-double-left-20-solid',
        },
        lastButton: {
          color: 'white',
          class: 'bg-whiteColor border-none ring-1 shadow-none  hover:bg-mainColor   hover:text-whiteColor',
          icon: 'i-heroicons-chevron-double-right-20-solid',
        },
        prevButton: {
          color: 'ghost',
          class: 'bg-whiteColor border-none ring-1 shadow-none hover:bg-mainColor   hover:text-whiteColor',
          icon: 'i-heroicons-chevron-left-20-solid',
        },
        nextButton: {
          color: 'white',
          class: 'bg-whiteColor border-none ring-1 shadow-none hover:bg-mainColor   hover:text-whiteColor',
          icon: 'i-heroicons-chevron-right-20-solid',
        },
      },
    },
    formGroup: {
      label: {
        wrapper: 'flex content-center items-center justify-between',
        base: 'block font-medium text-gray-700',
        required: "after:content-['*'] after:ms-0.5 after:text-red-500",
      },
    },
    input: {
      wrapper: 'relative',
      base: 'relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0',
      form: 'form-input',
      rounded: 'rounded-md',
      placeholder: 'placeholder-gray-400',
      file: {
        base: 'file:mr-1.5 file:font-medium file:text-gray-500 file:bg-transparent file:border-0 file:p-0 file:outline-none',
      },
      color: {
        white: {
          outline:
            'shadow-sm bg-white text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary-500',
        },
        gray: {
          outline:
            'shadow-sm bg-gray-50 text-gray-900  ring-1 ring-inset ring-gray-300  focus:ring-2 focus:ring-primary-500',
        },
      },
      variant: {
        outline:
          'shadow-sm bg-transparent text-gray-900 ring-1 ring-inset ring-{color}-500 focus:ring-2 focus:ring-{color}-500',
        none: 'bg-transparent focus:ring-0 focus:shadow-none',
      },
      icon: {
        base: 'flex-shrink-0 text-gray-400 ',
        color: 'text-{color}-500 ',
        loading: 'animate-spin',
        size: {
          '2xs': 'h-4 w-4',
          xs: 'h-4 w-4',
          sm: 'h-5 w-5',
          md: 'h-5 w-5',
          lg: 'h-5 w-5',
          xl: 'h-6 w-6',
        },
        leading: {
          wrapper: 'absolute inset-y-0 start-0 flex items-center',
          pointer: 'pointer-events-none',
          padding: {
            '2xs': 'px-2',
            xs: 'px-2.5',
            sm: 'px-2.5',
            md: 'px-3',
            lg: 'px-3.5',
            xl: 'px-3.5',
          },
        },
        trailing: {
          wrapper: 'absolute inset-y-0 end-0 flex items-center',
          pointer: 'pointer-events-none',
          padding: {
            '2xs': 'px-2',
            xs: 'px-2.5',
            sm: 'px-2.5',
            md: 'px-3',
            lg: 'px-3.5',
            xl: 'px-3.5',
          },
        },
      },
      default: {
        size: 'sm',
        color: 'white',
        variant: 'outline',
        loadingIcon: 'i-heroicons-arrow-path-20-solid',
      },
    },
    button: {
      base: 'focus:outline-none focus-visible:outline-0 disabled:cursor-not-allowed disabled:opacity-75 aria-disabled:cursor-not-allowed aria-disabled:opacity-75 flex-shrink-0',
      font: 'font-medium',
      rounded: 'rounded-md',
      truncate: 'text-left break-all line-clamp-1',
      block: 'w-full flex justify-center items-center',
      inline: 'inline-flex items-center',
      size: {
        '2xs': 'text-xs',
        xs: 'text-xs',
        sm: 'text-base',
        md: 'text-sm',
        lg: 'text-sm',
        xl: 'text-base',
      },
      gap: {
        '2xs': 'gap-x-1',
        xs: 'gap-x-1.5',
        sm: 'gap-x-1.5',
        md: 'gap-x-2',
        lg: 'gap-x-2.5',
        xl: 'gap-x-2.5',
      },
      padding: {
        '2xs': 'px-2 py-1',
        xs: 'px-2.5 py-1.5',
        sm: 'px-2.5 py-1.5',
        md: 'px-3 py-2',
        lg: 'px-3.5 py-2.5',
        xl: 'px-3.5 py-2.5',
      },
      square: {
        '2xs': 'p-1',
        xs: 'p-1.5',
        sm: 'p-1.5',
        md: 'p-2',
        lg: 'p-2.5',
        xl: 'p-2.5',
      },
      color: {
        white: {
          solid:
            'shadow-sm ring-1 ring-inset ring-gray-300 bg-whiteColor hover:bg-gray-50 disabled:bg-whiteColor aria-disabled:bg-whiteColor focus-visible:ring-2 focus-visible:ring-primary-500',
          ghost:
            'text-gray-mainColor focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500',
        },
        gray: {
          solid:
            'shadow-sm ring-1 ring-inset ring-gray-300 bg-gray-50 hover:bg-gray-100 disabled:bg-gray-50 aria-disabled:bg-gray-50 focus-visible:ring-2 focus-visible:ring-primary-500',
          ghost: 'text-mainColor hover:text-mainHover dark:text-mainColor',
          link: 'text-gray-500 underline-offset-4 hover:underline focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500',
        },
        black: {
          solid:
            'shadow-sm text-white bg-gray-900 hover:bg-gray-800 disabled:bg-gray-900 aria-disabled:bg-gray-900 focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500 ',
          link: 'bg-whiteColor text-mainColor hover:bg-lightHover',
        },
      },
      variant: {
        solid:
          'shadow-sm text-white bg-{color}-500 hover:bg-{color}-600 disabled:bg-{color}-500 aria-disabled:bg-{color}-500',
        outline:
          'ring-1 ring-inset ring-current text-{color}-500 hover:bg-{color}-50 disabled:bg-transparent aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-{color}-500',
        soft: 'text-{color}-500 bg-{color}-50 hover:bg-{color}-100 disabled:bg-{color}-50 aria-disabled:bg-{color}-50 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-{color}-500',
        ghost:
          'text-{color}-500  hover:bg-{color}-50 disabled:bg-transparent aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-{color}-500',
        link: 'text-{color}-500 hover:text-{color}-600 disabled:text-{color}-500 aria-disabled:text-{color}-500   underline-offset-4 hover:underline focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-{color}-500',
      },
      icon: {
        base: 'flex-shrink-0',
        loading: 'animate-spin',
        size: {
          '2xs': 'h-4 w-4',
          xs: 'h-4 w-4',
          sm: 'h-5 w-5',
          md: 'h-5 w-5',
          lg: 'h-5 w-5',
          xl: 'h-6 w-6',
        },
      },
      default: {
        size: 'sm',
        variant: 'solid',
        color: 'primary',
        loadingIcon: 'i-heroicons-arrow-path-20-solid',
      },
    },
    
  },
  APP_VERSION: '1.0.0',
  GIT_REVISION: 'fffffff',
})
