import { useNuxtApp } from '#app';

export function getKeycloakToken() {
  const nuxtApp = useNuxtApp();
  const keycloak = nuxtApp.$keycloak;
  return keycloak["token"];
}

export async function getKeycloakTokenRaw() {
  const env = process.env.test || process.env;
  const username = env.KEYCLOAK_USERNAME;
  const password = env.KEYCLOAK_PASSWORD;
  const clientId = 'lmt-ui-client';

  if (!clientId || !username || !password) {
    throw new Error('Keycloak credentials (client_id, username, or password) are not set in environment variables');
  }

  // Fetch the token from Keycloak (This is the login request)
  const response = await fetch('https://staging-kc.laniqo.dev/realms/laniqo/protocol/openid-connect/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams({
      'client_id': clientId,
      'grant_type': 'password',  // This is the login flow using password credentials
      'username': username,      // Your login username
      'password': password       // Your login password
    })
  });

  // Check if the response is okay
  if (!response.ok) {
    throw new Error('Failed to fetch token');
  }

  // Parse the response and get the access token
  const data = await response.json();
  const token = data.access_token;

  return token;
}


function getKeycloakSub() {
  const nuxtApp = useNuxtApp();
  const keycloak = nuxtApp.$keycloak;
  return keycloak.tokenParsed.sub;
}

export async function getUsers(token=getKeycloakToken()) {
  try {
    const config = useRuntimeConfig();
    const response = await $fetch(`${config.public.authUrl}/v1/users`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  } catch (error) {
    console.error('Error getting the users', error)
  }
}

export async function sendDeleteUser(user, token=getKeycloakToken()) {
  try {
    const config = useRuntimeConfig();
    const response = await $fetch(`${config.public.authUrl}/v1/users/${user}`, {
      method: 'DELETE',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return 'User deleted'
  } catch (error) {
    console.error('Error fetching documents:', error)
    return error
  }
}

export async function activateUser(user, token=getKeycloakToken()) {
  const config = useRuntimeConfig();
  const response = await $fetch(`${config.public.authUrl}/v1/users/${user}/activate`, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
  return "User activated"
}
export async function deactivateUser(user, token=getKeycloakToken()) {
  const config = useRuntimeConfig();
  const response = await $fetch(`${config.public.authUrl}/v1/users/${user}/deactivate`, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
  return "User deactivated"
}
export async function createUser(state, token=getKeycloakToken()) {
  const config = useRuntimeConfig();
  const response = await $fetch(
    `${config.public.authUrl}/v1/users?activate=${state.activate}&verify_email=${
      state.verify ? 'false' : 'true'
    }&send_email_verify=${state.verify}`,
    {
      method: 'POST',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: {
        email: state.email,
        first_name: state.first_name,
        last_name: state.last_name,
        password: state.password,
        temporary_password: true,
        username: state.email,
      },
    }
  )
  return response
}

export async function checkAccess(token=getKeycloakToken()) {
  try {
    const config = useRuntimeConfig();
    const response = await $fetch(`${config.public.authUrl}/v1/admin/access`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return true
  } catch (error) {
    console.error('Error getting the access', error)
    return false
  }
}

export async function changeUserData(data, token=getKeycloakToken()) {
  try {
    const config = useRuntimeConfig();
    const response = await $fetch(
      `${config.public.authUrl}/v1/users/${getKeycloakSub()}/self`,
      {
        method: 'PUT',
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    )
    return response
  } catch (error) {
    console.error('Error changing user data', error)
  }
}
