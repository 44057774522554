<template>
    <nav class="side__menu" @mouseenter="hovered = true" @mouseleave="hovered = false" :class="{ hovered }">
        <div class="side__menu__brand">
            <router-link to="/home" @click="hovered = false">
                <img :src="logoPath" width="80" />
            </router-link>
        </div>
        <router-link class="side__menu__user border-t border-[#B8CBEC]" to="/user-panel" @click="hovered = false">
            <img src="@/assets/TopBar/user.svg" width="22" />
            <p class="p__sideMenu ml-[0.5rem]"> {{ this.$keycloak["tokenParsed"]["email"] }} </p>
        </router-link>
        <div class="side__menu__user border-b border-[#B8CBEC] mb-3" @click="toggleLanguageMenu">
            <img class="mr-[0.5rem] p-[0.1rem]" src="@/assets/TopBar/icon_language.svg" width="22"/>
            <ListBoxPageLang v-if="hovered" />
        </div>
        <ul class="side__menu__nav">
            <li v-for="link in computedLinks" :key="link.path">
                <router-link :to="link.path" :class="{ disabled: !isRouteAvailable(link.path) }"
                    :tabindex="isRouteAvailable(link.path) ? 0 : -1" :aria-disabled="!isRouteAvailable(link.path)"
                    @click="hovered = false">
                    <img :src="link.icon" alt="menu icon" class="menu-icon" width="20" />
                    <span class="menu-label">{{ link.label }}</span>
                </router-link>
            </li>

        </ul>
        <!-- <div class="version">
            <p> ver. {{ appVersion }}+{{ gitRevision }} </p>
        </div> -->

        <div class="side__menu__settings">
            <FooterElement />

            <div class="side__menu__settings__item flex flex-row border-b border-t border-[#B8CBEC]" @click="logout">
                <img src="@/assets/TopBar/logout.svg" width="20">
                <p class="p__sideMenu text-nowrap"> {{ $t('menu.logout') }} </p>
            </div>
        </div>
    </nav>
</template>

<script>
import TextTranslatorImage from '@/assets/MainPage/TextTranslator.svg';
import GramaticalCorrection from '@/assets/MainPage/TextPolisher.svg';
import DocumentsImage from '@/assets/MainPage/Documents.svg';
import AddDocument from '@/assets/DocumentTranslate/DocumentTranslator.svg';
import Glossary from '@/assets/MainPage/Glossary.svg';
import FooterElement from '../Utilities/FooterElement.vue';
import ListBoxPageLang from '../Utilities/ListBoxPageLang.vue';

export default {
    components: {
        FooterElement,
        ListBoxPageLang,
    },
    data() {
        return {
            appVersion: this.$config.public.appVersion,
            gitRevision: this.$config.public.gitRevision,
            languageMenuExpanded: false,
            hovered: false,
            links: [
                { path: '/translate-text', icon: TextTranslatorImage },
                { path: '/translate-document', icon: AddDocument },
                { path: '/documents-viewer', icon: DocumentsImage },
                { path: '/language-correction', icon: GramaticalCorrection },
                { path: '/glossary', icon: Glossary },
            ],
            selectedLanguage: 'en',
            logoPath: '',
        };
    },
    async created() {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage !== null && savedLanguage !== 'undefined') {
            this.selectedLanguage = savedLanguage;
            this.$i18n.locale = savedLanguage;
        }
        else {
            localStorage.setItem('selectedLanguage', this.selectedLanguage);
        }
        const lastTarget = localStorage.getItem('lastSelectedTarget');
        if (lastTarget === null || lastTarget === 'undefined') {
            localStorage.setItem('lastSelectedTarget', this.selectedLanguage);
        }

        const config = this.$config;
        const instanceName = config.public.instanceName || 'laniqo';
        try {
            const logo = await import(`@/assets/TopBar/${instanceName}_logo.svg`);
            this.logoPath = logo.default;
        } catch (error) {
            console.warn(`Logo not found for instance: ${instanceName}`);
            const defaultLogo = await import('@/assets/TopBar/laniqo_logo.svg');
            this.logoPath = defaultLogo.default;
        }
    },
    computed: {
        computedLinks() {
            return this.links.map(link => ({
                ...link,
                label: this.$t(link.path.includes('translate-text') ? 'menu.text_translator' :
                    link.path.includes('translate-document') ? 'menu.new_document' :
                        link.path.includes('documents') ? 'menu.documents' :
                            link.path.includes('language-correction') ? 'menu.language_correction' :
                                link.path.includes('glossary') ? 'menu.glossary' : '')
            }));
        },
    },
    methods: {
        isRouteAvailable(path) {
            return this.$router.options.routes.some(route => route.path === path);
        },
        toggleLanguageMenu() {
            this.languageMenuExpanded = !this.languageMenuExpanded;
        },
        changeLanguage(lang) {
            event.stopPropagation();
            this.$i18n.locale = lang;
            this.selectedLanguage = lang;
            this.languageMenuExpanded = false;
            localStorage.setItem('selectedLanguage', this.selectedLanguage);
            window.location.reload();
        },
        logout() {
            this.$keycloak.logout({
                locale: this.$i18n.locale
            });

        }
    },
};
</script>

<style lang="scss" scoped>
.side__menu {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 5rem;
    background-color: $whiteColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    box-shadow: 0px 0.25rem 0.5rem 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
    transition: width 0.5s ease;

    &.hovered {

        width: 20rem;
        transition: width 0.5s ease;

        &:hover .menu-label {
            display: inline-block;
            text-wrap: nowrap;
        }

        &:hover .side__menu__user p {
            display: inline-block;
        }

        &:hover .side__menu__settings__item p {
            display: inline-block;
        }
    }


    &__brand {
        transition: transform 0.3s ease;
        margin-left: 0;
        margin-right: auto;


        img {
            padding: 0.75rem;
            padding-bottom: 0.75rem;
            padding-top: 0;
            transition: transform 0.3s ease;
        }

        &:hover {
            img {
                transform: scale(1, 1.05) translateY(2.5%);
            }
        }
    }

    &__user {
        width: 100%;
        padding: 0.75rem 1.8rem;
        display: flex;
        align-items: center;
        text-decoration: none;
        gap: 0.5rem;

        &:hover {
            background: $lightHover;
            transition: 0.8s;
        }
    }

    &__settings {
        position: absolute;
        bottom: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            padding: 0.75rem 1.8rem;

            gap: 0.5rem;

            &:hover {
                background: $lightHover;
                transition: 0.8s;
            }
        }
    }

    &__nav {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        align-items: center;
        justify-content: center;

        li {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 2.75rem;

            & a {
                display: flex;
                align-items: center;
                padding: 0.75rem 1.8rem;
                width: 100%;
                text-decoration: none;
                gap: 1rem;
                color: $mainColor;
                transition: background-color 0.3s ease;

                &:hover,
                &.router-link-active {
                    background: $lightHover;
                    transition: 0.8s;
                }

                &.disabled {
                    pointer-events: none;
                    color: #8c8c8c;

                    img {
                        filter: grayscale(100%);
                        opacity: 0.35;
                    }
                }


                .menu-label {
                    display: none;
                }
            }
        }
    }
}

.version {
    transition: opacity 0.3s;
    width: 100%;

    margin-top: 2rem;
    margin-left: 2rem;

    & p {
        opacity: 0;

        &:hover {
            opacity: 1;
        }
    }
}
</style>
