<script setup>
import SideMenu from '~/components/MainPage/SideMenu.vue'
import { ref, onMounted } from 'vue'
import { getKeycloakToken } from './components/Utilities/apis/auth'

const token = ref('');

onMounted(async () => {
  try {
    const fetchedToken = await getKeycloakToken();
    token.value = fetchedToken || '';
  } catch (error) {
    console.error('Error fetching token:', error);
  }
});
</script>

<template>
  <SideMenu />
  <NuxtLayout class="error__container" name="404">
    <div v-if="token && token.length > 0" class="error__container">
      <h3 class="text-5xl">Oops! We hit a snag.</h3>
      <h3 class="text-2xl">The page is not accessible. Try going back to the main site.</h3>
      <img src="@/assets/TopBar/error_404.svg" alt="404 Error">
      <button class="mt-15">
        <RouterLink class="text-white" to="/">
          Go back home
        </RouterLink>
      </button>
    </div>
  </NuxtLayout>
</template>

<style lang="scss">
.error__container {
  display: flex;
  margin-left: 5rem;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  gap: 1rem;
}

h3 {
  color:  $customGray;
  font-family: $mainFont;
  font-style: normal;
  font-weight: 510;
  line-height: 1.375rem;
}
</style>
