<template>
    <USelectMenu v-model="selected" :options="languages" :placeholder="getLanguageName($i18n.locale)"
        :uiMenu="uiMenuConfig" :ui="ui_select">
        <template #option="{ option: country }">
            <span :class="`flag-icon flag-icon-${getFlagIconClass(country)} !w-[1.5rem]`"></span>
            <span class="truncate text-[0.8125rem] text-mainColor font-mainFont">{{ getLanguageOwnName(getIso6393Code(country)) }}</span>
        </template>
        <template #trailing>
            <UIcon name="i-heroicons-chevron-down-20-solid" class="transition-transform duration-300" :size="22"/>
        </template>
    </USelectMenu>
</template>

<script>
import { getFlagIconClass, getLanguageName, getIso6391Code, getIso6393Code, getLanguageOwnName } from '~/components/Utilities/languages/languages.js';
import { messages } from '../../locales/locale.js';
export default {
    data() {
        return {
            languages: [],
            selected: "",
            ui_select: {
                wrapper: 'relative w-full px-0',
                base: 'bg-transparent w-full max-h-[1.25rem] dark:bg-transparent relative flex justify-start disabled:cursor-not-allowed px-0 focus:outline-none border-0',
                placeholder: 'text-mainColor flex justify-start',
                color: {
                    white: {
                        outline:
                            'text-md font-normal hover:bg-transparent cursor-pointer bg-transparent text-mainColor ring-0 ring-inset ',
                    },
                },
                variant: {
                    outline:
                        ' bg-transparent text-gray-900 ring-1 ring-inset ring-{color}-500 focus:ring-2 focus:ring-{color}-500',
                    none: 'bg-transparent focus:ring-0 focus:shadow-none',
                },
                icon: {
                    base: 'flex-shrink-0 text-mainColor justify-end',
                    trailing: {
                        wrapper: 'absolute inset-y-0 end-0 flex items-center',
                        pointer: 'pointer-events-none',
                        padding: {
                            sm: 'px-0 py-0',
                        }
                    }
                },
                padding: {
                    sm: 'pl-0',
                },

            },
            uiMenuConfig: {
                base: 'relative focus:outline-none overflow-y-auto scroll-py-1 top-[0.5rem]',
                background: 'bg-whiteColor dark:bg-whiteColor',
                input: 'block w-[calc(100%+0.5rem)] focus:ring-transparent text-sm px-3 py-1.5 text-mainColor bg-whiteColor border-0 border-b border-gray-200  sticky -top-1 -mt-1 mb-1 -mx-1 z-10 placeholder-gray-400 focus:outline-none',
                ring: 'ring-1 ring-gray-200',
                width: 'w-fit',
                padding: 'p-1',
                option: {
                    padding: 'pl-1.5 pr-8 py-1.5',
                    base: 'cursor-pointer select-none relative flex items-center justify-between gap-1',
                    color: 'text-mainColor',
                    container: 'flex items-center gap-1.5 min-w-0',
                    active: 'bg-lightHover ',
                    inactive: 'text-mainColor',
                    disabled: 'cursor-not-allowed opacity-50',
                    empty: 'text-sm text-gray-400 px-2 py-1.5',
                    selectedIcon: {
                        wrapper: 'invisible absolute inset-y-0 end-0 flex items-center',
                        padding: 'pe-2',
                        base: 'h-5 w-5 text-gray-900 flex-shrink-0'
                    },
                },
                default: {
                    selectedIcon: '',
                    clearSearchOnClose: false,
                    showCreateOptionWhen: 'empty',
                    searchablePlaceholder: {
                        label: this.$t('text_translator.search')
                    },
                    empty: {
                        label: this.$t('text_translator.no_options')
                    },
                    optionEmpty: {
                        label: this.$t('text_translator.no_results')
                    }
                },
            },

        };
    },
    created() {
        this.languages = Object.keys(messages).map((lang) => {
            return this.getLanguageName(lang);
        });
    },
    watch: {
        selected(newVal) {
            this.$i18n.locale = getIso6391Code(newVal);
            localStorage.setItem('selectedLanguage', getIso6391Code(newVal));
            window.location.reload();
        }
    },
    methods: {
        getFlagIconClass(languageCode) {
            return getFlagIconClass(languageCode);
        },
        getLanguageName(languageCode) {
            return getLanguageName(languageCode);
        },
        getIso6393Code(languageCode) {
            return getIso6393Code(languageCode);
        },
        getLanguageOwnName(languageCode) {
            return getLanguageOwnName(languageCode);
        },
    },
};
</script>