<template>
    <ul class="side__menu__footer">
        <li v-for="link in computedLinks" :key="link.path">
            <nuxt-link :to="link.path" @click="handleClick(link)">
                <span class="menu-label">{{ link.label }}</span>
            </nuxt-link>
        </li>
    </ul>
</template>

<script>
export default {
    data() {
        return {
            isScriptLoaded: false,
            links_pons: [
                { path: 'https://en.pons.com/p/privacy-policy', label: this.$t('footer.data_protection') },
                { path: 'https://en.pons.com/p/publisher/legal-notice', label: this.$t('footer.legal_notice') },
                { path: 'https://en.pons.com/p/terms', label: this.$t('footer.terms_and_conditions') },
                { path: 'javascript:void(0)', label: this.$t('footer.cookie_policy'), action: this.openCookiePolicy }
            ],
            links_pons_de: [
                { path: 'https://de.pons.com/p/privacy-policy', label: this.$t('footer.data_protection') },
                { path: 'https://de.pons.com/p/impressum', label: this.$t('footer.legal_notice') },
                { path: 'https://de.pons.com/p/agb', label: this.$t('footer.terms_and_conditions') },
                { path: 'javascript:void(0)', label: this.$t('footer.cookie_policy'), action: this.openCookiePolicy }
            ],
            links_laniqo: [
                { path: 'https://laniqo.com/privacy-policy/', label: this.$t('footer.privacy_policy') },
                { path: 'mailto:info@laniqo.com', label: this.$t('footer.contact') },
            ],
        };
    },
    computed: {
        computedLinks() {
            const config = this.$config;
            const instanceName = config.public.instanceName || 'laniqo';
            return instanceName === 'pons' ? this.getPonsLinks() : this.links_laniqo;
        },
    },
    methods: {
        handleClick(link) {
            if (link.action) {
                link.action();
            }
        },
        openCookiePolicy() {
            if (!this.isScriptLoaded) {
                const script = document.createElement('script');
                script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
                script.type = 'text/javascript';
                script.charset = 'UTF-8';
                script.setAttribute('data-domain-script', '0194a725-8d7e-7ef0-8b9f-a7cfe5f4d624');
                document.head.appendChild(script);

                script.onload = () => {
                    this.isScriptLoaded = true;
                    window.OptanonWrapper = function () {};
                    this.showCookieConsentForm();
                };
            } else {
                this.showCookieConsentForm();
            }
        },

        showCookieConsentForm() {
            if (window.Optanon && typeof window.Optanon.ToggleInfoDisplay === 'function') {
                window.Optanon.ToggleInfoDisplay();
            }
        },
        getPonsLinks() {
            return this.$i18n.locale === 'de' ? this.links_pons_de : this.links_pons;
        },
    },
};
</script>



<style lang="scss" scoped>
.side__menu {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 5rem;
    background-color: $whiteColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    box-shadow: 0px 0.25rem 0.5rem 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
    transition: width 0.5s ease;

    &.hovered {

        width: 20rem;
        transition: width 0.5s ease;

        &:hover .menu-label {
            display: inline-block;
            text-wrap: nowrap;
        }

        &:hover .side__menu__user p {
            display: inline-block;
        }

        &:hover .side__menu__settings__item p {
            display: inline-block;
        }

        &:hover .side__menu__footer {
            display: inline-block;
        }
    }

    &__footer {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        align-items: center;
        justify-content: center;

        li {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            & a {
                display: flex;
                align-items: center;
                padding: 0.5rem 1.8rem;
                width: 100%;
                text-decoration: underline;
                font-size: 0.8rem;
                background: $whiteColor;

                color: $customGray;
                transition: background-color 0.3s ease;

                &:hover {
                    background: $backgroundColor;
                    transition: 0.5s;
                }


                .menu-label {
                    display: none;
                }
            }
        }
    }
}
</style>