
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as admin_45paneloFvoZ5j5RpMeta } from "/tmp/builder/pages/admin-panel.vue?macro=true";
import { default as document_45editorr9zPV3VIV5Meta } from "/tmp/builder/pages/document-editor.vue?macro=true";
import { default as documents_45viewer3BzeisMSG9Meta } from "/tmp/builder/pages/documents-viewer.vue?macro=true";
import { default as glossaryEZJB1yZuTZMeta } from "/tmp/builder/pages/glossary.vue?macro=true";
import { default as home9BkxGDkMsJMeta } from "/tmp/builder/pages/home.vue?macro=true";
import { default as indexWQgpWrwewEMeta } from "/tmp/builder/pages/index.vue?macro=true";
import { default as language_45correctionXYNSU3MRv8Meta } from "/tmp/builder/pages/language-correction.vue?macro=true";
import { default as translate_45documentrP9PgBkd9RMeta } from "/tmp/builder/pages/translate-document.vue?macro=true";
import { default as translate_45textYm0uFzc17vMeta } from "/tmp/builder/pages/translate-text.vue?macro=true";
import { default as user_45panel2mgHJSxXciMeta } from "/tmp/builder/pages/user-panel.vue?macro=true";
export default [
  {
    name: "admin-panel",
    path: "/admin-panel",
    component: () => import("/tmp/builder/pages/admin-panel.vue")
  },
  {
    name: "document-editor",
    path: "/document-editor",
    component: () => import("/tmp/builder/pages/document-editor.vue")
  },
  {
    name: "documents-viewer",
    path: "/documents-viewer",
    component: () => import("/tmp/builder/pages/documents-viewer.vue")
  },
  {
    name: "glossary",
    path: "/glossary",
    component: () => import("/tmp/builder/pages/glossary.vue")
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/tmp/builder/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/builder/pages/index.vue")
  },
  {
    name: "language-correction",
    path: "/language-correction",
    component: () => import("/tmp/builder/pages/language-correction.vue")
  },
  {
    name: "translate-document",
    path: "/translate-document",
    component: () => import("/tmp/builder/pages/translate-document.vue")
  },
  {
    name: "translate-text",
    path: "/translate-text",
    component: () => import("/tmp/builder/pages/translate-text.vue")
  },
  {
    name: "user-panel",
    path: "/user-panel",
    component: () => import("/tmp/builder/pages/user-panel.vue")
  }
]